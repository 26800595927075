import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Container,
  Body,
  Page,
  LinkTag,
  SocialContainer,
  Overlaybg,
} from "../styles/Navigation.styles";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import NavButton from "./NavButton";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../store/actions/exports";
import { useHistory } from "react-router-dom";

const NavigationMenu = ({
  hasBackground,
  userLogged,
  language,
  changeLanguage,
  toggleAuthModal,
  setMode,
  userSignout,
}) => {
  const history = useHistory();
  const [isOn, setState] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    shouldAnimate &&
      !isOn &&
      setTimeout(() => {
        setShouldAnimate(false);
      }, 0);
  }, [shouldAnimate, isOn]);

  const closeHandler = () => {
    setShouldAnimate(true);
    setState(!isOn);
  };

  useEffect(() => {
    const header = document.getElementById("header");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky + 0) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const redirectHandler = (link) => {
    history.push(link);
    closeHandler();
  };

  return (
    <header>
      <div id="header"></div>
      <div className="logo">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/logowhite.png`}
            className="img-fluid"
            alt="#"
          />
        </Link>
      </div>
      <div className="languages">
        <span
          onClick={() => changeLanguage("en")}
          className={language === "en" ? "underlined" : undefined}
        >
          EN
        </span>
        <span
          onClick={() => changeLanguage("si")}
          className={language === "si" ? "underlined" : undefined}
        >
          SI
        </span>
      </div>
      <Wrapper open={isOn} shouldAnimate={shouldAnimate}>
        <Overlaybg open={isOn} shouldAnimate={shouldAnimate} />
        <Container
          open={isOn}
          onClick={closeHandler}
          hasBackground={hasBackground}
        >
          <NavButton open={isOn} />
        </Container>

        <Body className="midwrpr" open={isOn} shouldAnimate={shouldAnimate}>
          <div className="conPage">
            <Page
              className="mainBtn"
              onClick={() => redirectHandler(`/#${t("link-home")}`)}
            >
              <LinkTag>{t("nav-home")}</LinkTag>
            </Page>
            {userLogged && userLogged.user.role === 1 && (
              <Page
                className="mainBtn"
                onClick={() => redirectHandler(`/dashboard`)}
              >
                <LinkTag>{t("nav-dashboard")}</LinkTag>
              </Page>
            )}
            <Page
              className="mainBtn"
              onClick={() => redirectHandler(`/#${t("link-about-us")}`)}
            >
              <LinkTag>{t("nav-about-us")}</LinkTag>
            </Page>
            <Page
              className="mainBtn"
              onClick={() => redirectHandler(`/#${t("link-products")}`)}
            >
              <LinkTag>{t("nav-products")}</LinkTag>
            </Page>
            <Page
              className="mainBtn"
              onClick={() => redirectHandler(`/#${t("link-contact")}`)}
            >
              <LinkTag>{t("nav-contact")}</LinkTag>
            </Page>
            {userLogged && (
              <Page
                className="mainBtn"
                onClick={() => {
                  userSignout();
                  redirectHandler("/");
                }}
              >
                <LinkTag>{t("nav-logout")}</LinkTag>
              </Page>
            )}
          </div>
          <div className="info">
            <span>+386 70 219 951</span>
            <span>pohistvocoper@gmail.com</span>
            <span>Zadobrova 77, 3211 Škofja vas</span>
          </div>
        </Body>
        <SocialContainer className="soc-icon" open={isOn}>
          <span>{t("nav-follow-us")}:</span>
          <a href="https://www.facebook.com/PohistvoCOPER">
            <span className="socicon">
              <i className="fa fa-facebook-f"></i>
            </span>
          </a>
          <a href="https://www.instagram.com/pohistvocoper">
            <span className="socicon">
              <i className="fa  fa-instagram"></i>
            </span>
          </a>
        </SocialContainer>
      </Wrapper>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.userLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignout: () => dispatch(actions.userSignout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
