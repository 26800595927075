import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavigationMenu from "../components/Nav";
import Footer from "../components/Footer";
import AuthModal from "../components/AuthModal";
import ScrollToTopBtn from "../components/ScrollToTop";

const Layout = ({ children, isAuth, language, changeLanguage }) => {
  const location = useLocation();
  const [authModal, setAuthModal] = useState(false);
  const [mode, setMode] = useState("register");
  const [token, setToken] = useState();

  const toggleAuthModal = (data) => {
    data ? setAuthModal(data) : setAuthModal(!authModal);
  };

  useEffect(() => {
    if (location) {
      const link = location.pathname.split("/")[1];
      const tokenType = location.pathname.split("/")[2];
      const token = location.pathname.split("/")[3];
      if ((link === "prijava" || link === "login") && !authModal) {
        setMode("login");
        toggleAuthModal();
      } else if (tokenType === "activate" && token) {
        setMode("login");
        toggleAuthModal(true);
      } else if (tokenType === "reset" && token) {
        setMode("reset");
        toggleAuthModal();
      }
      setToken(token);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <NavigationMenu
        isAuth={isAuth}
        toggleAuthModal={toggleAuthModal}
        setMode={setMode}
        language={language}
        changeLanguage={changeLanguage}
      />
      {children}
      <ScrollToTopBtn />
      <Footer />
      <AuthModal
        authModal={authModal}
        toggleAuthModal={toggleAuthModal}
        setAuthModal={setAuthModal}
        mode={mode}
        setMode={setMode}
        token={token}
      />
    </React.Fragment>
  );
};

export default Layout;
