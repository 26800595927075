import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../store/actions/exports";

const Contact = ({ mailSuccess, mailLoading, mailError, mailSend }) => {
  const { t } = useTranslation();

  let contactTitle = t("contact-title1");
  let splittedContactTitle = contactTitle.split(" ");

  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = values;

  const handleChange = (name) => (event) => {
    let value = event.target.value;
    setValues({ ...values, [name]: value });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    mailSend({ values });
  };

  useEffect(() => {
    setValues({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }, [mailSuccess]);

  const showAlert = (message, type) => <div id={type}>{message}</div>;

  return (
    <div className="container-fluid">
      <Efect />
      <Efect1 />
      <Efect2 />

      <div className="jumbotron head" />

      <Reveal effect="fadeInUp">
        <section className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                {t("contact-title")}
                <span className="color"> {splittedContactTitle[0]} </span>
                {splittedContactTitle[1]}
                <span className="color"> {splittedContactTitle[2]}</span>.
              </h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className="container pt-0">
          <div className="row">
            <div className="col-md-6">
              <div className="text-side">
                <div className="address">
                  <h3 className="heading">{t("contact-form-title")}</h3>
                  <div className="list">
                    <i className="fa fa-map-marker"></i>
                    Zadobrova 77, 3211 Škofja vas
                  </div>
                  <div className="list">
                    <i className="fa fa-envelope-o"></i>
                    <a
                      href="mailto:pohistvocoper@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      pohistvocoper@gmail.com
                    </a>
                  </div>
                  <div className="list">
                    <i className="fa fa-phone"></i>
                    +386 70 219 951
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-side">
                <form className="formcontact" onSubmit={sendMessage}>
                  <label>{t("contact-form-name")}</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange("name")}
                    required
                  />
                  <label>{t("contact-form-email")}</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange("email")}
                    required
                  />
                  <label>{t("contact-form-subject")}</label>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={handleChange("subject")}
                    required
                  />
                  <label>{t("contact-form-message")}</label>
                  <textarea
                    name="message"
                    value={message}
                    onChange={handleChange("message")}
                    required
                  />
                  {mailSuccess &&
                    showAlert(t("contact-form-success"), "success")}
                  {mailError && showAlert(t("contact-form-fail"), "failed")}
                  <button type="submit" id="buttonsent">
                    <span className="shine"></span>
                    <span>{t("contact-form-button")}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Reveal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mailError: state.contact.mailError,
    mailSuccess: state.contact.mailSuccess,
    mailLoading: state.contact.mailLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    mailSend: (mailData) => dispatch(actions.mailSend(mailData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Contact));
