import React, { useState, useEffect } from "react";
import { Row, Col, Alert, FormGroup, Button, Spinner, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as actions from "../store/actions/exports";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "./Loader";

const Login = ({
  user,
  authenticationSuccess,
  authenticationError,
  authenticationLoading,
  userActivate,
  userActivateSuccess,
  userActivateError,
  userActivateLoading,
  userSignin,
  authenticationMessageClear,
  authenticate,
  setMode,
  token,
  setAuthModal,
}) => {
  const location = useLocation();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const { email, password } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleLogin = (e) => {
    e.persist();
    userSignin(values);
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    authenticationMessageClear();
  }, [authenticationMessageClear]);

  useEffect(() => {
    if (user) {
      authenticate(user);
      setAuthModal(false);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token && location.pathname.split("/")[2] === "activate") {
      userActivate(token);
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userActivateSuccess) {
      setTimeout(() => {
        window.location.pathname = "/";
      }, [3000]);
    }
  }, [userActivateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col xs={12}>
        <h4 className="text-center">Login</h4>
        {!userActivateLoading ? (
          userActivateSuccess ? (
            <React.Fragment>
              {userActivateSuccess &&
                showAlert("User registered successfully!", "success")}
              <p>You will be redirected, please wait...</p>
            </React.Fragment>
          ) : (
            <Row>
              <Col xs={12}>
                {userActivateSuccess ||
                userActivateError ||
                authenticationSuccess ||
                authenticationError
                  ? (userActivateSuccess &&
                      showAlert("User registered successfully!", "success")) ||
                    (userActivateError &&
                      showAlert(userActivateError, "danger")) ||
                    (authenticationSuccess &&
                      showAlert(
                        "You have been successfully logged in!",
                        "success"
                      )) ||
                    (authenticationError &&
                      showAlert(authenticationError, "danger"))
                  : null}
                <AvForm onValidSubmit={handleLogin}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <AvField
                          type="email"
                          name="email"
                          placeholder="Enter email..."
                          id="email"
                          onChange={handleChange("email")}
                          required
                          errorMessage=""
                          value={email}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Enter a valid email address!",
                            },
                            pattern: {
                              value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                              errorMessage: "Enter a valid email address!",
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <AvField
                          type="password"
                          name="password"
                          placeholder="Enter password..."
                          id="password"
                          value={password}
                          onChange={handleChange("password")}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Enter a valid password!",
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <Button
                        type="submit"
                        className="btn-custom primary"
                        block
                      >
                        {!authenticationLoading ? (
                          "Login"
                        ) : (
                          <Spinner children={false} size="sm" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center mt-3">
                    <p
                      className="clickable text-dark mb-0"
                      onClick={() => setMode("forgot")}
                    >
                      Forgot your password?
                    </p>
                  </Row>
                </AvForm>
              </Col>
              <Col xs={12} className="text-center mt-3 rounded">
                <div
                  className="clickable pattern-bg hasColor text-white p-4"
                  onClick={() => setMode("register")}
                >
                  You are not registered yet?
                </div>
              </Col>
            </Row>
          )
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    userActivateSuccess: state.user.userActivateSuccess,
    userActivateError: state.user.userActivateError,
    userActivateLoading: state.user.userActivateLoading,

    user: state.user.userLogged,
    authenticationLoading: state.user.authenticationLoading,
    authenticationError: state.user.authenticationError,
    authenticationSuccess: state.user.authenticationSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    userSignin: (email, password) =>
      dispatch(actions.userSignin(email, password)),
    userActivate: (token) => dispatch(actions.userActivate(token)),
    isAuthenticated: () => dispatch(actions.isAuthenticated()),
    authenticate: (user) => dispatch(actions.authenticate(user)),
    userMessageClear: () => dispatch(actions.userMessageClear()),
    authenticationMessageClear: () =>
      dispatch(actions.authenticationMessageClear()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
