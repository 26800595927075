import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { history } from "./history";
import RoutesSwitch from "./RoutesSwitch";
import { connect } from "react-redux";
import * as actions from "./store/actions/exports";
import "./styles/animated-on3step.css";
import "./styles/main.scss";
import Layout from "./containers/Layout";
import i18n from "./i18n";

const App = ({ isAuthenticated, userLogged }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [language, setLanguage] = useState();

  const changeLanguage = (language) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    changeLanguage(localStorage.getItem("i18nextLng").split("-")[0]);
  }, []);

  useEffect(() => {
    isAuthenticated();
  }, [isAuthenticated]);

  useEffect(() => {
    if (userLogged) {
      setIsAuth(true);
      userLogged.user.role === 1 && setIsAdmin(true);
    } else {
      setIsAuth(false);
    }
  }, [userLogged]);

  return (
    <Router history={history}>
      <Layout language={language} changeLanguage={changeLanguage}>
        <RoutesSwitch isAuth={isAuth} isAdmin={isAdmin} language={language} />
      </Layout>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticationLoading: state.user.authenticationLoading,
    userLoggedError: state.user.userLoggedError,
    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    isAuthenticated: () => dispatch(actions.isAuthenticated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
