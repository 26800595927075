import * as actionTypes from "./actionTypes";
import { API } from "../config";

//send email
export const mailSendStart = () => {
  return {
    type: actionTypes.SEND_MAIL_START,
  };
};
export const mailSendSuccess = () => {
  return {
    type: actionTypes.SEND_MAIL_SUCCESS,
  };
};
export const mailSendFail = (error) => {
  return {
    type: actionTypes.SEND_MAIL_FAIL,
    error: error,
  };
};
export const mailSend = (data) => {
  return (dispatch) => {
    dispatch(mailSendStart());
    fetch(`${API}/send-mail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => dispatch(mailSendSuccess("Email sent successfully.")))
      .catch((err) => {
        dispatch(mailSendFail("Error has occurred. Try again."));
      });
  };
};

//send user activation email
export const mailUserActivationSendStart = () => {
  return {
    type: actionTypes.SEND_USER_ACTIVATION_MAIL_START,
  };
};
export const mailUserActivationSendSuccess = () => {
  return {
    type: actionTypes.SEND_USER_ACTIVATION_MAIL_SUCCESS,
  };
};
export const mailUserActivationSendFail = (error) => {
  return {
    type: actionTypes.SEND_USER_ACTIVATION_MAIL_FAIL,
    error: error,
  };
};
export const mailUserActivationSend = (data) => {
  return (dispatch) => {
    dispatch(mailUserActivationSendStart());
    fetch(`${API}/send-user-activation-mail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(mailUserActivationSendFail(data.error));
        } else {
          dispatch(mailUserActivationSendSuccess());
        }
      })
      .catch((error) => {
        dispatch(
          mailUserActivationSendFail("An error occurred, please try again!")
        );
      });
  };
};

//send user forgot password mail
export const mailForgotPasswordSendStart = () => {
  return {
    type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_START,
  };
};
export const mailForgotPasswordSendSuccess = () => {
  return {
    type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_SUCCESS,
  };
};
export const mailForgotPasswordSendFail = (error) => {
  return {
    type: actionTypes.SEND_FORGOT_PASSWORD_MAIL_FAIL,
    error: error,
  };
};
export const mailForgotPasswordSend = (data) => {
  return (dispatch) => {
    dispatch(mailForgotPasswordSendStart());
    fetch(`${API}/forgot-password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(mailForgotPasswordSendFail(data.error));
        } else {
          dispatch(mailForgotPasswordSendSuccess("Email sent successfully."));
        }
      })
      .catch((error) => {
        dispatch(
          mailForgotPasswordSendFail("An error occurred, please try again!")
        );
      });
  };
};

//reset password
export const passwordResetStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
};
export const passwordResetSuccess = () => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
  };
};
export const passwordResetFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: error,
  };
};
export const passwordReset = (data) => {
  return (dispatch) => {
    dispatch(passwordResetStart());
    fetch(`${API}/reset-password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(passwordResetFail(data.error));
        } else {
          dispatch(passwordResetSuccess());
        }
      })
      .catch((error) => {
        dispatch(passwordResetFail("An error occurred, please try again!"));
      });
  };
};

//check user reset token
export const resetTokenValidateStart = () => {
  return {
    type: actionTypes.VALIDATE_RESET_TOKEN_START,
  };
};
export const resetTokenValidateSuccess = () => {
  return {
    type: actionTypes.VALIDATE_RESET_TOKEN_SUCCESS,
  };
};
export const resetTokenValidateFail = (error) => {
  return {
    type: actionTypes.VALIDATE_RESET_TOKEN_FAIL,
    error: error,
  };
};
export const resetTokenValidate = (resetToken) => {
  return (dispatch) => {
    dispatch(resetTokenValidateStart());
    fetch(`${API}/reset-token-validation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ resetToken }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(resetTokenValidateFail(data.error));
        } else {
          dispatch(resetTokenValidateSuccess());
        }
      })
      .catch((error) => {
        dispatch(
          resetTokenValidateFail("An error occurred, please try again!")
        );
      });
  };
};

//clear contact message
export const contactMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_CONTACT_MESSAGE_SUCCESS,
  };
};
export const contactMessageClear = () => {
  return (dispatch) => {
    dispatch(contactMessageClearSuccess());
  };
};
