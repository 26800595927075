import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all product
export const productsFetchStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_START,
  };
};
export const productsFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    products: data.data,
  };
};
export const productsCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_COUNT_SUCCESS,
    count: data,
  };
};
export const productsFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FAIL,
    error: error,
  };
};
export const productsFetch = (skip, limit, myFilters) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  const data = {
    limit,
    skip,
    myFilters,
  };

  return (dispatch) => {
    dispatch(productsFetchStart());
    fetch(`${API}/products/count/${language}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let productCount = result.count;
        fetch(`${API}/products/${language}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(productsFetchFail(data.error));
            } else {
              dispatch(productsCountFetchSuccess(productCount));
              dispatch(productsFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(productsFetchFail(error));
          });
      });
  };
};

//get product by Id
export const productFetchStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_START,
  };
};
export const productFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCT_SUCCESS,
    product: data,
  };
};
export const productFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_FAIL,
    error: error,
  };
};
export const productFetch = (productId) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productFetchStart());
    fetch(`${API}/product/${productId}/${language}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productFetchFail(data.error));
        } else {
          dispatch(productFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(productFetchFail(error));
      });
  };
};

//get all product
export const productsFilteredFetchStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FILTERED_START,
  };
};
export const productsFilteredFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FILTERED_SUCCESS,
    products: data,
  };
};
export const productsFilteredCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FILTERED_COUNT_SUCCESS,
    count: data,
  };
};
export const productsFilteredFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_FILTERED_FAIL,
    error: error,
  };
};
export const productsFilteredFetch = (skip, limit, filters) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  const data = {
    limit,
    skip,
    filters,
  };
  return (dispatch) => {
    dispatch(productsFilteredFetchStart());
    fetch(`${API}/products/filtered/count/${language}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let productCount = result.count;
        fetch(`${API}/products/filtered/${language}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(productsFilteredFetchFail(data.error));
            } else {
              dispatch(productsFilteredCountFetchSuccess(productCount));
              dispatch(productsFilteredFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(productsFilteredFetchFail(error));
          });
      });
  };
};

//create product
export const productCreateStart = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_START,
  };
};
export const productCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
  };
};
export const productCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_PRODUCT_FAIL,
    error: error,
  };
};
export const productCreate = (userId, token, productData) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productCreateStart());
    fetch(`${API}/product/create/${userId}/${language}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: productData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productCreateFail(data.error));
        } else {
          dispatch(productCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(productCreateFail(error));
      });
  };
};

//update category
export const productUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_PRODUCT_START,
  };
};
export const productUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUCCESS,
    product: data,
  };
};
export const productUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_FAIL,
    error: error,
  };
};
export const productUpdate = (productId, userId, token, productData) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productUpdateStart());
    fetch(`${API}/product/update/${productId}/${userId}/${language}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: productData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productUpdateFail(data.error));
        } else {
          dispatch(productUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(productUpdateFail(error));
      });
  };
};

//delete category
export const productDeleteStart = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_START,
  };
};
export const productDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUCCESS,
  };
};
export const productDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_PRODUCT_FAIL,
    error: error,
  };
};
export const productDelete = (productId, userId, token) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productDeleteStart());
    fetch(`${API}/product/${productId}/${userId}/${language}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productDeleteFail(data.error));
        } else {
          dispatch(productDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(productDeleteFail(error));
      });
  };
};

//clear category message
export const productMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_PRODUCT_MESSAGE_SUCCESS,
  };
};
export const productMessageClear = () => {
  return (dispatch) => {
    dispatch(productMessageClearSuccess());
  };
};
