import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="container-fluid black_more">
      <div className="row m-2-hor">
        <div className="col-md-6">
          <div className="footer-col">
            <div className="heading">
              <h2>{t("footer-about-us-title")}</h2>
            </div>
            <div className="content">
              <p>{t("footer-about-us-text")}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-col">
            <div className="heading">{t("footer-menu-title")}</div>
            <div className="content">
              <Link to={`/#${t("link-home")}`} className="link">
                {t("footer-menu-link1")}
              </Link>
              <Link to={`/#${t("link-about-us")}`} className="link">
                {t("footer-menu-link2")}
              </Link>
              <Link to={`/#${t("link-products")}`} className="link">
                {t("footer-menu-link3")}
              </Link>
              <Link to={`/#${t("link-contact")}`} className="link">
                {t("footer-menu-link4")}
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footer-col">
            <div className="heading">{t("footer-follow-us-title")}</div>
            <div className="content">
              <p>{t("footer-follow-us-text")}</p>
              <div>
                <a href="https://www.facebook.com/PohistvoCOPER">
                  <div className="socialicon">
                    <span className="shine"></span>
                    <i className="fa fa-facebook-f"></i>
                  </div>
                </a>
                <a href="https://www.instagram.com/pohistvocoper">
                  <div className="socialicon">
                    <span className="shine"></span>
                    <i className="fa  fa-instagram"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="row m-2-hor">
          <div className="col-md-6">
            <div className="content">
              {t("footer-developed-by")}
              <a
                href="https://www.produkto.io"
                target="_blank"
                rel="noopener noreferrer"
                className="color"
              >
                {" Produkto.io"}
              </a>
              . {t("footer-rights")}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
