import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get all categories
export const productCategoriesFetchStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORIES_START,
  };
};
export const productCategoriesFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS,
    categories: data.data,
  };
};
export const productCategoriesCountFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORIES_COUNT_SUCCESS,
    count: data,
  };
};
export const productCategoriesFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORIES_FAIL,
    error: error,
  };
};
export const productCategoriesFetch = (skip, limit, myFilters) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  const data = {
    limit,
    skip,
    myFilters,
  };
  return (dispatch) => {
    dispatch(productCategoriesFetchStart());
    fetch(`${API}/productCategories/count/${language}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ myFilters }),
    })
      .then((response) => response.json())
      .then((result) => {
        let productCategoryCount = result.count;
        fetch(`${API}/productCategories/${language}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              dispatch(productCategoriesFetchFail(data.error));
            } else {
              dispatch(
                productCategoriesCountFetchSuccess(productCategoryCount)
              );
              dispatch(productCategoriesFetchSuccess(data));
            }
          })
          .catch((error) => {
            dispatch(productCategoriesFetchFail(error));
          });
      });
  };
};

//get category by Name
export const productCategoryFetchStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORY_START,
  };
};
export const productCategoryFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORY_SUCCESS,
    category: data,
  };
};
export const productCategoryFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_CATEGORY_FAIL,
    error: error,
  };
};
export const productCategoryFetch = (productCategoryName) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productCategoryFetchStart());
    fetch(`${API}/productCategory/${productCategoryName}/${language}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productCategoryFetchFail(data.error));
        } else {
          dispatch(productCategoryFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(productCategoryFetchFail(error));
      });
  };
};

//create category
export const productCategoryCreateStart = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_CATEGORY_START,
  };
};
export const productCategoryCreateSuccess = () => {
  return {
    type: actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS,
  };
};
export const productCategoryCreateFail = (error) => {
  return {
    type: actionTypes.CREATE_PRODUCT_CATEGORY_FAIL,
    error: error,
  };
};
export const productCategoryCreate = (userId, token, productCategoryData) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productCategoryCreateStart());
    fetch(`${API}/productCategory/create/${userId}/${language}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: productCategoryData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productCategoryCreateFail(data.error));
        } else {
          dispatch(productCategoryCreateSuccess());
        }
      })
      .catch((error) => {
        dispatch(productCategoryCreateFail(error));
      });
  };
};

//update category
export const productCategoryUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_PRODUCT_CATEGORY_START,
  };
};
export const productCategoryUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS,
    category: data,
  };
};
export const productCategoryUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_CATEGORY_FAIL,
    error: error,
  };
};
export const productCategoryUpdate = (
  productCategoryId,
  userId,
  token,
  productCategoryData
) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productCategoryUpdateStart());
    fetch(
      `${API}/productCategory/update/${productCategoryId}/${userId}/${language}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: productCategoryData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productCategoryUpdateFail(data.error));
        } else {
          dispatch(productCategoryUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(productCategoryUpdateFail(error));
      });
  };
};

//delete category
export const productCategoryDeleteStart = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_CATEGORY_START,
  };
};
export const productCategoryDeleteSuccess = () => {
  return {
    type: actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS,
  };
};
export const productCategoryDeleteFail = (error) => {
  return {
    type: actionTypes.DELETE_PRODUCT_CATEGORY_FAIL,
    error: error,
  };
};
export const productCategoryDelete = (productCategoryId, userId, token) => {
  let language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  return (dispatch) => {
    dispatch(productCategoryDeleteStart());
    fetch(`${API}/productCategory/${productCategoryId}/${userId}/${language}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(productCategoryDeleteFail(data.error));
        } else {
          dispatch(productCategoryDeleteSuccess());
        }
      })
      .catch((error) => {
        dispatch(productCategoryDeleteFail(error));
      });
  };
};

//clear category message
export const productCategoryMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_PRODUCT_CATEGORY_MESSAGE_SUCCESS,
  };
};
export const productCategoryMessageClear = () => {
  return (dispatch) => {
    dispatch(productCategoryMessageClearSuccess());
  };
};
