import React from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import ManageCategories from "./dashboard/ManageCategories";
import CreateCategory from "./dashboard/CreateCategory";
import UpdateCategory from "./dashboard/UpdateCategory";
import ManageProducts from "./dashboard/ManageProducts";
import CreateProduct from "./dashboard/CreateProduct";
import UpdateProduct from "./dashboard/UpdateProduct";

const options = [
  {
    type: "navitem",
    option: "products",
    link: "products",
  },
  {
    type: "navitem",
    option: "create-product",
    link: "create-product",
  },
  {
    type: "navitem",
    option: "product-categories",
    link: "product-categories",
  },
  {
    type: "navitem",
    option: "create-product-category",
    link: "create-product-category",
  },
];

const Panel = ({ language }) => {
  const { t } = useTranslation();
  const { section } = useParams();
  const MyComponent = () => {
    let component;
    switch (section) {
      case "product-categories":
        component = <ManageCategories language={language} />;
        break;
      case "create-product-category":
        component = <CreateCategory language={language} />;
        break;
      case "update-product-category":
        component = <UpdateCategory language={language} />;
        break;
      case "products":
        component = <ManageProducts language={language} />;
        break;
      case "create-product":
        component = <CreateProduct language={language} />;
        break;
      case "update-product":
        component = <UpdateProduct language={language} />;
        break;
      default:
        component = <ManageProducts language={language} />;
    }
    return (
      <Col xs={12} lg={8}>
        <div className="panel">{component}</div>
      </Col>
    );
  };

  return (
    <Row className="dashboard mb-5">
      <Col xs={12} lg={4} className="dashboard-items mb-4 mb-lg-0">
        <div className="sidemenu">
          <div className="sidemenu-header">
            <h5>{t("sections")}</h5>
          </div>
          <div className="sidemenu-body">
            <ul>
              {options.map((item, i) =>
                item.type === "navitem" ? (
                  <li className="sidemenu-item" key={i}>
                    <Link to={`/dashboard/${item.link}`}>{t(item.option)}</Link>
                  </li>
                ) : (
                  <hr />
                )
              )}
            </ul>
          </div>
        </div>
      </Col>
      {MyComponent()}
    </Row>
  );
};

export default Panel;
