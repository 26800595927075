import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Alert, FormGroup, Button, Spinner, Label } from "reactstrap";
import * as actions from "../store/actions/exports";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

const ForgotPassword = ({
  mailForgotPasswordSend,
  contactMessageClear,
  mailSuccess,
  mailLoading,
  mailError,
  setMode,
}) => {
  let form = useRef();
  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleResetPassword = (e, email) => {
    e.persist();
    mailForgotPasswordSend(email);
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    contactMessageClear();
  }, [contactMessageClear]);

  useEffect(() => {
    if (mailSuccess) {
      form.reset();
    }
  }, [mailSuccess]);

  return (
    <Row>
      <Col xs={12}>
        <h4 className="text-center">Reset password</h4>
        {mailSuccess || mailError
          ? (mailSuccess &&
              showAlert("Successfully sent reset request!", "success")) ||
            (mailError && showAlert(mailError, "danger"))
          : null}
        <AvForm onValidSubmit={handleResetPassword} ref={(f) => (form = f)}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="email">Email</Label>
                <AvField
                  type="email"
                  name="email"
                  placeholder="Enter email..."
                  id="email"
                  onChange={handleChange("email")}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <Button type="submit" className="btn-custom primary" block>
                {!mailLoading ? (
                  "Reset"
                ) : (
                  <Spinner children={false} size="sm" />
                )}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </Col>
      <Col xs={12} className="text-center mt-3">
        <div
          className="clickable pattern-bg hasColor text-white p-4"
          onClick={() => setMode("login")}
        >
          Do you want to login?
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.userLogged,
    userLoggedLoading: state.user.userLoggedLoading,
    userLoggedError: state.user.userLoggedError,
    userLoggedSuccess: state.user.userLoggedSuccess,

    mailSuccess: state.contact.mailSuccess,
    mailError: state.contact.mailError,
    mailLoading: state.contact.mailLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    contactMessageClear: () => dispatch(actions.contactMessageClear()),
    mailForgotPasswordSend: (data) =>
      dispatch(actions.mailForgotPasswordSend(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
