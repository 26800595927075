import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Button, FormGroup, Alert, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DropzoneBasic from "../DropzoneBasic";
import Loader from "../Loader";
import { connect } from "react-redux";
import * as actions from "../../store/actions/exports";
import { useTranslation } from "react-i18next";

const UpdateCategory = ({
  productCategory,
  productCategoryLoading,
  productCategoryUpdateSuccess,
  productCategoryUpdateLoading,
  productCategoryUpdateError,
  productCategoryFetch,
  productCategoryUpdate,
  productCategoryMessageClear,
  userLogged,
  language,
}) => {
  const { t } = useTranslation();
  const { sectionId } = useParams();
  const [currentLanguage, setCurrentLanguage] = useState("");
  const history = useHistory();
  const [values, setValues] = useState({
    name: {
      en: "",
      si: "",
    },
    slug: {
      en: "",
      si: "",
    },
    icon: "",
    formData: new FormData(),
  });
  const { name, slug, formData } = values;

  const handleChange = (name) => (event) => {
    let value = event.target.value;
    let lang = event.target.lang;
    setValues({
      ...values,
      [name]: { ...values[name], [lang]: value },
    });
    formData.set(name, value);
  };

  const handleFileInput = (file, name) => {
    setValues({ ...values, [name]: file });
    formData.set(name, file);
  };

  const submitHandler = (e) => {
    e.persist();
    productCategoryUpdate(
      sectionId,
      userLogged.user._id,
      userLogged.token,
      formData
    );
  };

  useEffect(() => {
    if (sectionId) {
      productCategoryMessageClear();
      productCategoryFetch(sectionId);
    }
  }, [sectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productCategory) {
      setCurrentLanguage(language);

      setValues({
        ...values,
        name: productCategory.name,
        slug: productCategory.slug,
      });
    }
  }, [productCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productCategoryUpdateSuccess) {
      history.push(
        `/dashboard/update-product-category/${values.slug[language]}`
      );
    }
  }, [productCategoryUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.name && values.slug) {
      formData.set("name", JSON.stringify(values["name"]));
      formData.set("slug", JSON.stringify(values["slug"]));
    }
  }, [values.name, values.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentLanguage && language !== currentLanguage) {
      history.push(`/dashboard/product-categories`);
    }
  }, [currentLanguage, language]); // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  return (
    <Row>
      <Col xs={12}>
        {productCategoryUpdateError &&
          showAlert(productCategoryUpdateError, "danger")}
        {productCategoryUpdateSuccess &&
          showAlert(t("category-update-success-message"), "success")}
      </Col>
      <Col xs={12}>
        <div className="section-title">
          <h5>{t("update-product-category")}</h5>
        </div>
      </Col>
      <Col xs={12}>
        {!productCategoryLoading && productCategory ? (
          <AvForm onValidSubmit={(e) => submitHandler(e)}>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <AvField
                    name="title"
                    id="title"
                    type="text"
                    className="form-control"
                    placeholder={t("enter-name-placeholder-en")}
                    onChange={handleChange("name")}
                    value={name["en"]}
                    lang="en"
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <AvField
                    name="subtitle"
                    id="subtitle"
                    type="text"
                    className="form-control"
                    placeholder={t("enter-slug-placeholder-en")}
                    onChange={handleChange("slug")}
                    value={slug["en"]}
                    lang="en"
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <AvField
                    name="title"
                    id="title"
                    type="text"
                    className="form-control"
                    placeholder={t("enter-name-placeholder-si")}
                    onChange={handleChange("name")}
                    value={name["si"]}
                    lang="si"
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <AvField
                    name="subtitle"
                    id="subtitle"
                    type="text"
                    className="form-control"
                    placeholder={t("enter-slug-placeholder-si")}
                    onChange={handleChange("slug")}
                    value={slug["si"]}
                    lang="si"
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <DropzoneBasic
                    handleFileInput={handleFileInput}
                    name={"icon"}
                    jpeg={true}
                    mainImage={true}
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <Button type="submit" className="btn-custom primary">
                  {!productCategoryUpdateLoading ? (
                    t("update")
                  ) : (
                    <Spinner color="white" size="sm" children={false} />
                  )}
                </Button>
              </Col>
            </Row>
          </AvForm>
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategory: state.productCategory.productCategory,
    productCategorySuccess: state.productCategory.productCategorySuccess,
    productCategoryLoading: state.productCategory.productCategoryLoading,
    productCategoryError: state.productCategory.productCategoryError,

    productCategoryUpdateSuccess:
      state.productCategory.productCategoryUpdateSuccess,
    productCategoryUpdateLoading:
      state.productCategory.productCategoryUpdateLoading,
    productCategoryUpdateError:
      state.productCategory.productCategoryUpdateError,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCategoryFetch: (categorySlug) =>
      dispatch(actions.productCategoryFetch(categorySlug)),
    productCategoryUpdate: (categoryId, userId, token, categoryData) =>
      dispatch(
        actions.productCategoryUpdate(categoryId, userId, token, categoryData)
      ),
    productCategoryMessageClear: () =>
      dispatch(actions.productCategoryMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCategory);
