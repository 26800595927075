import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  user: null,
  userSuccess: null,
  userError: null,
  userLoading: false,

  userLogged: null,
  userLoggedError: null,
  userLoggedLoading: false,
  userLoggedSuccess: false,

  userActivateSuccess: null,
  userActivateError: null,
  userActivateLoading: false,

  authenticationSuccess: null,
  authenticationError: null,
  authenticationLoading: null,

  userUpdateSuccess: null,
  userUpdateError: null,
  userUpdateLoading: false,
};

//get user by ID
const userFetchStart = (state) => {
  return updateObject(state, {
    userSuccess: null,
    userLoading: true,
    userError: null,
  });
};
const userFetchSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    userSuccess: true,
    userLoading: false,
    userError: null,
  });
};
const userFetchFail = (state, action) => {
  return updateObject(state, {
    userError: action.error,
    userSuccess: false,
    userLoading: false,
  });
};

//signin user
const userSigninStart = (state) => {
  return updateObject(state, {
    authenticationSuccess: null,
    authenticationLoading: true,
    authenticationError: null,
  });
};
const userSigninSuccess = (state, action) => {
  return updateObject(state, {
    userLogged: action.user,
    userLoggedSuccess: true,
    userLoggedLoading: false,
    authenticationLoading: false,
    authenticationSuccess: null,
    authenticationError: null,
    userLoggedError: null,
  });
};
const userSigninFail = (state, action) => {
  return updateObject(state, {
    authenticationError: action.error,
    authenticationSuccess: false,
    authenticationLoading: false,
  });
};

//signout user
const userSignoutStart = (state) => {
  return updateObject(state, {
    userSignoutSuccess: null,
    userSignoutLoading: true,
    userSignoutError: null,
  });
};
const userSignoutSuccess = (state) => {
  return updateObject(state, {
    userLogged: null,
    userSignoutSuccess: true,
    userSignoutLoading: false,
    userSignoutError: null,
  });
};
const userSignoutFail = (state, action) => {
  return updateObject(state, {
    userSignoutError: action.error,
    userSignoutSuccess: false,
    userSignoutLoading: false,
  });
};

//signup user
const userSignupStart = (state) => {
  return updateObject(state, {
    userSuccess: null,
    userLoading: true,
    userError: null,
  });
};
const userSignupSuccess = (state, action) => {
  return updateObject(state, {
    userSuccess: true,
    userLoading: false,
    userError: null,
  });
};
const userSignupFail = (state, action) => {
  return updateObject(state, {
    userError: action.error,
    userSuccess: false,
    userLoading: false,
  });
};

//activate user account
const userActivationStart = (state) => {
  return updateObject(state, {
    userActivateSuccess: null,
    userActivateLoading: true,
    userActivateError: null,
  });
};
const userActivationSuccess = (state) => {
  return updateObject(state, {
    userActivateSuccess: true,
    userActivateLoading: false,
    userActivateError: null,
  });
};
const userActivationFail = (state, action) => {
  return updateObject(state, {
    userActivateError: action.error,
    userActivateSuccess: false,
    userActivateLoading: false,
  });
};

//update user password
const userPasswordUpdateStart = (state) => {
  return updateObject(state, {
    userUpdateSuccess: null,
    userUpdateLoading: true,
    userUpdateError: null,
  });
};
const userPasswordUpdateSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    userUpdateSuccess: true,
    userUpdateLoading: false,
    userUpdateError: null,
  });
};
const userPasswordUpdateFail = (state, action) => {
  return updateObject(state, {
    userUpdateError: action.error,
    userUpdateSuccess: false,
    userUpdateLoading: false,
  });
};

//clear user message
const clearUserMessageSuccess = (state) => {
  return updateObject(state, {
    userSuccess: null,
    userError: null,
    userActivateSuccess: null,
    userActivateError: null,
    userLoggedSuccess: null,
    userLoggedError: null,
    userUpdateSuccess: null,
    userUpdateError: null,
  });
};

const authenticationMessageClear = (state) => {
  return updateObject(state, {
    authenticationSuccess: null,
    authenticationError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN_USER_START:
      return userSigninStart(state);
    case actionTypes.SIGNIN_USER_SUCCESS:
      return userSigninSuccess(state, action);
    case actionTypes.SIGNIN_USER_FAIL:
      return userSigninFail(state, action);

    case actionTypes.SIGNUP_USER_START:
      return userSignupStart(state);
    case actionTypes.SIGNUP_USER_SUCCESS:
      return userSignupSuccess(state, action);
    case actionTypes.SIGNUP_USER_FAIL:
      return userSignupFail(state, action);

    case actionTypes.SIGNOUT_USER_START:
      return userSignoutStart(state);
    case actionTypes.SIGNOUT_USER_SUCCESS:
      return userSignoutSuccess(state, action);
    case actionTypes.SIGNOUT_USER_FAIL:
      return userSignoutFail(state, action);

    case actionTypes.ACTIVATE_USER_START:
      return userActivationStart(state);
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return userActivationSuccess(state, action);
    case actionTypes.ACTIVATE_USER_FAIL:
      return userActivationFail(state, action);

    case actionTypes.FETCH_USER_START:
      return userFetchStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return userFetchSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return userFetchFail(state, action);

    case actionTypes.UPDATE_USER_PASSWORD_START:
      return userPasswordUpdateStart(state);
    case actionTypes.UPDATE_USER_PASSWORD_SUCCESS:
      return userPasswordUpdateSuccess(state, action);
    case actionTypes.UPDATE_USER_PASSWORD_FAIL:
      return userPasswordUpdateFail(state, action);

    case actionTypes.CLEAR_USER_MESSAGE_SUCCESS:
      return clearUserMessageSuccess(state, action);
    case actionTypes.CLEAR_AUTHENTICATION_MESSAGE_SUCCESS:
      return authenticationMessageClear(state);

    default:
      return state;
  }
};
export default reducer;
