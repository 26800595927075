import * as actionTypes from "./actionTypes";
import { API } from "../config";

//get user by ID
export const userFetchStart = () => {
  return {
    type: actionTypes.FETCH_USER_START,
  };
};
export const userFetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    user: data,
  };
};
export const userFetchFail = (error) => {
  return {
    type: actionTypes.FETCH_USER_FAIL,
    error: error,
  };
};
export const userFetch = (token, userId, userRole) => {
  let language = localStorage.getItem("i18nextLng");
  return (dispatch) => {
    dispatch(userFetchStart());
    fetch(`${API}/user/${userId}/${language}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userRole }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(userFetchFail(data.error));
        } else {
          dispatch(userFetchSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(userFetchFail(error));
      });
  };
};

//user signin
export const userSigninStart = () => {
  return {
    type: actionTypes.SIGNIN_USER_START,
  };
};
export const userSigninSuccess = (data) => {
  return {
    type: actionTypes.SIGNIN_USER_SUCCESS,
    user: data,
  };
};
export const userSigninFail = (error) => {
  return {
    type: actionTypes.SIGNIN_USER_FAIL,
    error: error,
  };
};
export const userSignin = (user) => {
  return (dispatch) => {
    dispatch(userSigninStart());
    fetch(`${API}/signin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(userSigninFail(data.error));
        } else {
          dispatch(userSigninSuccess(data));
        }
      })
      .catch((err) => {
        dispatch(userSigninFail(err));
      });
  };
};

//signup user
export const userSignupStart = () => {
  return {
    type: actionTypes.SIGNUP_USER_START,
  };
};
export const userSignupSuccess = () => {
  return {
    type: actionTypes.SIGNUP_USER_SUCCESS,
  };
};
export const userSignupFail = (error) => {
  return {
    type: actionTypes.SIGNUP_USER_FAIL,
    error: error,
  };
};
export const userSignup = (user) => {
  return (dispatch) => {
    dispatch(userSignupStart());
    fetch(`${API}/signup`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(userSignupFail(data.error));
        } else {
          dispatch(userSignupSuccess());
        }
      })
      .catch((error) => {
        dispatch(userSignupFail(error));
      });
  };
};

//signout user
export const userSignoutStart = () => {
  return {
    type: actionTypes.SIGNOUT_USER_START,
  };
};
export const userSignoutSuccess = () => {
  return {
    type: actionTypes.SIGNOUT_USER_SUCCESS,
  };
};
export const userSignoutFail = (error) => {
  return {
    type: actionTypes.SIGNOUT_USER_FAIL,
    error: error,
  };
};
export const userSignout = () => {
  return (dispatch) => {
    dispatch(userSignoutStart());
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      fetch(`${API}/signout`, {
        method: "GET",
      })
        .then(() => {
          dispatch(userSignoutSuccess());
        })
        .catch((error) => dispatch(userSignoutFail(error)));
    }
  };
};

//update user password
export const userPasswordUpdateStart = () => {
  return {
    type: actionTypes.UPDATE_USER_PASSWORD_START,
  };
};
export const userPasswordUpdateSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_USER_PASSWORD_SUCCESS,
    user: data,
  };
};
export const userPasswordUpdateFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_PASSWORD_FAIL,
    error: error,
  };
};
export const userPasswordUpdate = (userId, token, userData) => {
  return (dispatch) => {
    dispatch(userPasswordUpdateStart());
    fetch(`${API}/user-password/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: userData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(userPasswordUpdateFail(data.error));
        } else {
          dispatch(authenticate({ token: token, user: data }));
          dispatch(userPasswordUpdateSuccess(data));
        }
      })
      .catch((error) => {
        dispatch(userPasswordUpdateFail(error));
      });
  };
};

//activate user account
export const userActivateStart = () => {
  return {
    type: actionTypes.ACTIVATE_USER_START,
  };
};
export const userActivateSuccess = () => {
  return {
    type: actionTypes.ACTIVATE_USER_SUCCESS,
  };
};
export const userActivateFail = (error) => {
  return {
    type: actionTypes.ACTIVATE_USER_FAIL,
    error: error,
  };
};
export const userActivate = (token) => {
  return (dispatch) => {
    dispatch(userActivateStart());
    fetch(`${API}/account-activate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(userActivateFail(data.error));
        } else {
          dispatch(authenticate({ token: data.token, user: data.user }));
          dispatch(userActivateSuccess());
        }
      })
      .catch((error) => {
        dispatch(userActivateFail(error));
      });
  };
};

//check user token and signin
export const isAuthenticated = () => {
  return (dispatch) => {
    if (localStorage.getItem("jwt")) {
      dispatch(userSigninSuccess(JSON.parse(localStorage.getItem("jwt"))));
    }
  };
};

//set user token in localstorage
export const authenticate = (data) => {
  return (dispatch) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("jwt", JSON.stringify(data));
    }
  };
};

//clear user message
export const userMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_USER_MESSAGE_SUCCESS,
  };
};
export const userMessageClear = () => {
  return (dispatch) => {
    dispatch(userMessageClearSuccess());
  };
};

//clear authentication message
export const authenticationMessageClearSuccess = () => {
  return {
    type: actionTypes.CLEAR_AUTHENTICATION_MESSAGE_SUCCESS,
  };
};
export const authenticationMessageClear = () => {
  return (dispatch) => {
    dispatch(authenticationMessageClearSuccess());
  };
};
