import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Alert, FormGroup, Button, Spinner, Label } from "reactstrap";
import * as actions from "../store/actions/exports";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Loader from "./Loader";

const ResetPassword = ({
  passwordReset,
  passwordResetSuccess,
  passwordResetLoading,
  passwordResetError,
  resetTokenValidate,
  resetTokenValidateSuccess,
  resetTokenValidateLoading,
  resetTokenValidateError,
  token,
  setMode,
}) => {
  let form = useRef();
  const [localError, setLocalError] = useState();
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const checkPasswords = () => {
    if (password !== confirmPassword) {
      return false;
    }
    return true;
  };

  const handleResetPassword = () => {
    if (checkPasswords()) {
      setLocalError(false);
      passwordReset({ token, password });
    } else if (!checkPasswords()) {
      setLocalError(true);
    }
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    resetTokenValidate(token);
  }, [token, resetTokenValidate]);

  useEffect(() => {
    if (passwordResetSuccess) {
      form.reset();
    }
  }, [passwordResetSuccess]);

  return (
    <Row>
      <Col xs={12}>
        <h4 className="text-center">Reset password</h4>
        {!resetTokenValidateLoading ? (
          <Row>
            <Col xs={12}>
              {resetTokenValidateError || passwordResetError
                ? (
                    <Row>
                      <Col xs={12}>
                        {resetTokenValidateError &&
                          showAlert(resetTokenValidateError, "danger")}
                      </Col>
                      <Col xs={12}>
                        <p
                          className="clickable text-dark mb-0 text-center"
                          onClick={() => {
                            setMode("forgot");
                          }}
                        >
                          Request a new password reset?
                        </p>
                      </Col>
                    </Row>
                  ) ||
                  (passwordResetError &&
                    showAlert(passwordResetError, "danger"))
                : null}
              {passwordResetSuccess &&
                showAlert("Password has been changed successfully.", "success")}
              {localError && showAlert("Passwords must match.", "danger")}
              {!resetTokenValidateError && resetTokenValidateSuccess && (
                <AvForm
                  onValidSubmit={handleResetPassword}
                  ref={(f) => (form = f)}
                >
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <AvField
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Enter password..."
                          onChange={handleChange("password")}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Enter a valid password!",
                            },
                            minLength: {
                              value: 8,
                              errorMessage:
                                "Password must be between 8 and 16 characters!",
                            },
                            maxLength: {
                              value: 16,
                              errorMessage:
                                "Password must be between 8 and 16 characters!",
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="password">Confirm password</Label>
                        <AvField
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Confirm password..."
                          onChange={handleChange("confirmPassword")}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Enter a valid password!",
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <Button
                        type="submit"
                        className="btn-custom primary"
                        block
                      >
                        {!passwordResetLoading ? (
                          "Reset"
                        ) : (
                          <Spinner children={false} size="sm" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              )}
            </Col>
            <Col xs={12} className="text-center mt-3">
              <div
                className="clickable pattern-bg hasColor text-white p-4"
                onClick={() => setMode("login")}
              >
                Do you want to login?
              </div>
            </Col>
          </Row>
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    passwordResetSuccess: state.contact.passwordResetSuccess,
    passwordResetError: state.contact.passwordResetError,
    passwordResetLoading: state.contact.passwordResetLoading,

    resetTokenValidateSuccess: state.contact.resetTokenValidateSuccess,
    resetTokenValidateError: state.contact.resetTokenValidateError,
    resetTokenValidateLoading: state.contact.resetTokenValidateLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    passwordReset: (data) => dispatch(actions.passwordReset(data)),
    resetTokenValidate: (data) => dispatch(actions.resetTokenValidate(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
