import React from "react";
import { useTranslation } from "react-i18next";

const AboutHome = () => {
  const { t } = useTranslation();

  let heading = t("abouthome-title");
  let splittedHeading = heading.split(" ");

  return (
    <section className="container-fluid">
      <div className="row m-2-hor">
        <div className="col-md-6 pt-5">
          <div className="col-home">
            <div className="thumb">
              <img
                src={`${process.env.PUBLIC_URL}/images/about-us.jpg`}
                className="img-fluid"
                alt="#"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="dflex-center">
            <div className="col-home mt-md-0 mt-5">
              <div className="heading">
                <span className="color">{splittedHeading[0]}</span>{" "}
                {splittedHeading[1]}
              </div>
              <div className="content">{t("abouthome-content-1")}</div>
              <div className="content">{t("abouthome-content-2")}</div>
              <div className="content">{t("abouthome-content-3")}</div>
              <div className="content">{t("abouthome-content-4")}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
