import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Button, Alert, FormGroup, Label, Spinner } from "reactstrap";
import * as actions from "../store/actions/exports";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

let initialValues = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  formData: new FormData(),
};

const Register = ({
  mailUserActivationSend,
  userMessageClear,
  mailSuccess,
  mailError,
  mailLoading,
  setMode,
  userLogged,
  authenticate,
  authenticationError,
  authenticationMessageClear,
  contactMessageClear,
  toggleAuthModal,
}) => {
  let form = useRef();
  const history = useHistory();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    formData: new FormData(),
  });
  const [localError, setLocalError] = useState();
  const { password, confirmPassword, formData } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    formData.set(name, event.target.value);
  };

  const checkFormCompletion = () => {
    if (password !== confirmPassword) {
      return false;
    }
    return true;
  };

  const handleRegister = () => {
    userMessageClear();
    setValues({ ...values, loading: true });
    if (checkFormCompletion()) {
      setLocalError(false);
      mailUserActivationSend(formData);
    } else if (!checkFormCompletion()) {
      setLocalError(true);
    }
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    userMessageClear();
    authenticationMessageClear();
    contactMessageClear();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userLogged) {
      authenticate(userLogged);
      history.push("/");
      userMessageClear();
    }
  }, [userLogged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mailSuccess) {
      form.reset();
      setValues(initialValues);
    }
  }, [mailSuccess]);

  return (
    <Row>
      <Col xs={12}>
        <h4 className="text-center">Register</h4>
        {mailSuccess || mailError || authenticationError
          ? (mailSuccess &&
              showAlert(
                "User created successfully. Check your email to activate the account!",
                "success"
              )) ||
            (mailError && showAlert(mailError, "danger")) ||
            (authenticationError && showAlert(authenticationError, "danger"))
          : null}
        {localError ? showAlert("Passwords do not match!", "danger") : null}
        <AvForm onValidSubmit={handleRegister} ref={(f) => (form = f)}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="name">Name</Label>
                <AvField
                  type="text"
                  name="name"
                  placeholder="Enter name..."
                  id="name"
                  onChange={handleChange("name")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Enter valid data!",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label for="email">Email</Label>
                <AvField
                  type="email"
                  name="email"
                  placeholder="Enter email..."
                  id="email"
                  onChange={handleChange("email")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Enter valid data!",
                    },
                    pattern: {
                      value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                      errorMessage: "Enter valid data!",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label for="password">Password</Label>
                <AvField
                  type="password"
                  name="password"
                  placeholder="Enter password..."
                  id="password"
                  onChange={handleChange("password")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Enter valid data!",
                    },
                    minLength: {
                      value: 8,
                      errorMessage:
                        "Password must be between 8 and 16 characters!",
                    },
                    maxLength: {
                      value: 16,
                      errorMessage:
                        "Password must be between 8 and 16 characters!",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label for="password">Confirm password</Label>
                <AvField
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password..."
                  id="confirm-password"
                  onChange={handleChange("confirmPassword")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Enter valid data!",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <Button type="submit" className="btn-custom primary">
                {!mailLoading ? (
                  "Register"
                ) : (
                  <Spinner color="light" size="sm" children={false} />
                )}
              </Button>
            </Col>
          </Row>
        </AvForm>
        <Row className="d-flex justify-content-center mt-3"></Row>
      </Col>
      <Col xs={12} className="text-center mt-3 rounded">
        <div
          className="clickable pattern-bg hasColor text-white p-4"
          onClick={() => setMode("login")}
        >
          Already registered?
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    userActivateSuccess: state.user.userActivateSuccess,
    userActivateError: state.user.userActivateError,
    userActivateLoading: state.user.userActivateLoading,

    mailSuccess: state.contact.mailSuccess,
    mailError: state.contact.mailError,
    mailLoading: state.contact.mailLoading,

    userLogged: state.user.userLogged,

    authenticationLoading: state.user.authenticationLoading,
    authenticationError: state.user.authenticationError,
    authenticationSuccess: state.user.authenticationSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    mailUserActivationSend: (userData) =>
      dispatch(actions.mailUserActivationSend(userData)),
    authenticate: (user) => dispatch(actions.authenticate(user)),
    userMessageClear: () => dispatch(actions.userMessageClear()),
    contactMessageClear: () => dispatch(actions.contactMessageClear()),
    authenticationMessageClear: () =>
      dispatch(actions.authenticationMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
