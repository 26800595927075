import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Button } from "reactstrap";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { FaArrowRight, FaArrowLeft, FaTrash, FaPen } from "react-icons/fa";
import * as actions from "../../store/actions/exports";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import ActionModal from "../ActionModal";
import { useTranslation } from "react-i18next";

const ManageCategories = ({
  productCategories,
  productCategoriesCount,
  productCategoriesLoading,
  productCategoryDeleteSuccess,
  productCategoryDeleteError,
  productCategoryDeleteLoading,
  productCategoriesFetch,
  productCategoryDelete,
  productCategoryMessageClear,
  userLogged,
  language,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [deleteElement, setDeleteElement] = useState();
  const [paginationProps, setPaginationProps] = useState({
    limit: 15,
    skip: 0,
    page: 1,
  });
  const [myFilters] = useState({
    order: "asc",
  });
  const { limit, skip, page } = paginationProps;

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const pageSelectHandler = (pageNumber) => {
    productCategoriesFetch(pageNumber * limit, limit, myFilters);
    setPaginationProps({
      ...paginationProps,
      page: pageNumber + 1,
      skip: pageNumber * limit,
    });
  };

  const onDeleteHandler = () => {
    setOpen(false);
    productCategoryDelete(deleteElement, userLogged.user._id, userLogged.token);
  };

  useEffect(() => {
    productCategoryMessageClear();
    productCategoriesFetch(skip, limit, myFilters);
  }, [productCategoryMessageClear, productCategoriesFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productCategoryDeleteSuccess) {
      if (productCategories.length - 1 !== 0) {
        productCategoriesFetch(skip, limit, myFilters);
      } else if (productCategories.length - 1 === 0) {
        const newPage = page > 1 ? page - 2 : 0;
        productCategoriesFetch(newPage * limit, limit, myFilters);
        setPaginationProps({
          ...paginationProps,
          page: newPage + 1,
          skip: newPage * limit,
        });
      }
    }
  }, [productCategoryDeleteSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          {productCategoryDeleteSuccess &&
            showAlert(t("delete-product-category-success-message"), "success")}
          {productCategoryDeleteError &&
            showAlert(productCategoryDeleteError, "danger")}
        </Col>
        <Col xs={12}>
          <div className="section-title">
            <h5>{t("product-categories")}</h5>
          </div>
        </Col>
        {productCategories &&
        !productCategoriesLoading &&
        !productCategoryDeleteLoading ? (
          productCategoriesCount > 0 ? (
            <Col xs={12}>
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th className="actions">{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {productCategories.map((category, i) => (
                    <tr key={i}>
                      <td data-title="Name">
                        <span>{category.name[language]}</span>
                      </td>
                      <td data-title="Actions">
                        <Row>
                          <Col xs={6}>
                            <Link
                              to={`/dashboard/update-product-category/${category.slug[language]}`}
                              className="btn-custom primary"
                            >
                              <FaPen size={20} />
                            </Link>
                          </Col>
                          <Col xs={6}>
                            <Button
                              onClick={() => {
                                setDeleteElement(category._id);
                                onOpenModal();
                              }}
                              className="btn-custom danger"
                            >
                              <FaTrash size={20} />
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          ) : (
            <Col className="not-found text-center">
              <div>{t("no-results")}</div>
            </Col>
          )
        ) : (
          <Col className="text-center">
            <Loader />
          </Col>
        )}
        {Math.ceil(+productCategoriesCount / limit) > 1 && (
          <Col xs={12} className="mx-auto">
            <ReactPaginate
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(+productCategoriesCount / limit)}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              containerClassName={
                "pagination icon-pagination pagination-center"
              }
              forcePage={page - 1}
              onPageChange={(selectedIndex) => {
                pageSelectHandler(selectedIndex.selected);
              }}
              previousLabel={<FaArrowLeft size={15} />}
              nextLabel={<FaArrowRight size={15} />}
              activeClassName={"active"}
            />
          </Col>
        )}
      </Row>
      <ActionModal
        open={open}
        onCloseModal={onCloseModal}
        onDeleteHandler={onDeleteHandler}
        title="delete-alert-title"
        description="delete-alert-desc"
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategories: state.productCategory.productCategories,
    productCategoriesSuccess: state.productCategory.productCategoriesSuccess,
    productCategoriesCount: state.productCategory.productCategoriesCount,
    productCategoriesError: state.productCategory.productCategoriesError,
    productCategoriesLoading: state.productCategory.productCategoriesLoading,

    productCategoryDeleteSuccess:
      state.productCategory.productCategoryDeleteSuccess,
    productCategoryDeleteError:
      state.productCategory.productCategoryDeleteError,
    productCategoryDeleteLoading:
      state.productCategory.productCategoryDeleteLoading,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCategoriesFetch: (skip, limit, myFilters) =>
      dispatch(actions.productCategoriesFetch(skip, limit, myFilters)),
    productCategoryDelete: (categoryId, userId, token) =>
      dispatch(actions.productCategoryDelete(categoryId, userId, token)),
    productCategoryMessageClear: () =>
      dispatch(actions.productCategoryMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories);
