import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  product: null,
  productSuccess: null,
  productError: null,
  productLoading: false,

  products: null,
  productsCount: null,
  productsSuccess: null,
  productsError: null,
  productsLoading: false,

  productsFiltered: null,
  productsFilteredCount: null,
  productsFilteredSuccess: null,
  productsFilteredError: null,
  productsFilteredLoading: false,

  productUpdateSuccess: null,
  productUpdatError: null,
  productUpdateLoading: false,

  productDeleteSuccess: null,
  productDeleteError: null,
  productDeleteLoading: false,
};

//get all products
const productsFetchStart = (state) => {
  return updateObject(state, {
    productsSuccess: null,
    productsError: null,
    productsLoading: true,
  });
};
const productsFetchSuccess = (state, action) => {
  return updateObject(state, {
    products: action.categories,
    productsSuccess: true,
    productsError: null,
    productsLoading: false,
  });
};
const productsCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    productsCount: action.count,
  });
};
const productsFetchFail = (state, action) => {
  return updateObject(state, {
    productsError: action.error,
    productsSuccess: false,
    productsLoading: false,
  });
};

//get filtered products
const productsFilteredFetchStart = (state) => {
  return updateObject(state, {
    productsFilteredSuccess: null,
    productsFilteredError: null,
    productsFilteredLoading: true,
  });
};
const productsFilteredFetchSuccess = (state, action) => {
  return updateObject(state, {
    productsFiltered: action.products,
    productsFilteredSuccess: true,
    productsFilteredError: null,
    productsFilteredLoading: false,
  });
};
const productsFilteredCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    productsFilteredCount: action.count,
  });
};
const productsFilteredFetchFail = (state, action) => {
  return updateObject(state, {
    productsFilteredError: action.error,
    productsFilteredSuccess: false,
    productsFilteredLoading: false,
  });
};

//get product by Id
const productFetchStart = (state) => {
  return updateObject(state, {
    productSuccess: null,
    productError: null,
    productLoading: true,
  });
};
const productFetchSuccess = (state, action) => {
  return updateObject(state, {
    product: action.product,
    productSuccess: true,
    productError: null,
    productLoading: false,
  });
};
const productFetchFail = (state, action) => {
  return updateObject(state, {
    productError: action.error,
    productSuccess: false,
    productLoading: false,
  });
};

//create product
const productCreateStart = (state) => {
  return updateObject(state, {
    productSuccess: null,
    productError: null,
    productLoading: true,
  });
};
const productCreateSuccess = (state) => {
  return updateObject(state, {
    productSuccess: true,
    productError: null,
    productLoading: false,
  });
};
const productCreateFail = (state, action) => {
  return updateObject(state, {
    productError: action.error,
    productSuccess: false,
    productLoading: false,
  });
};

//update product
const productUpdateStart = (state) => {
  return updateObject(state, {
    productUpdateSuccess: null,
    productUpdateError: null,
    productUpdateLoading: true,
  });
};
const productUpdateSuccess = (state, action) => {
  return updateObject(state, {
    product: action.product,
    productUpdateSuccess: true,
    productUpdateError: null,
    productUpdateLoading: false,
  });
};
const productUpdateFail = (state, action) => {
  return updateObject(state, {
    productUpdateError: action.error,
    productUpdateSuccess: false,
    productUpdateLoading: false,
  });
};

//delete product
const productDeleteStart = (state) => {
  return updateObject(state, {
    productDeleteSuccess: null,
    productDeleteError: null,
    productDeleteLoading: true,
  });
};
const productDeleteSuccess = (state) => {
  return updateObject(state, {
    productDeleteSuccess: true,
    productDeleteError: null,
    productDeleteLoading: false,
  });
};
const productDeleteFail = (state, action) => {
  return updateObject(state, {
    productDeleteError: action.error,
    productDeleteSuccess: false,
    productDeleteLoading: false,
  });
};

//clear product message
const productMessageClearSuccess = (state) => {
  return updateObject(state, {
    productSuccess: null,
    productError: null,
    productsSuccess: null,
    productsError: null,
    productsFilteredSuccess: null,
    productsFilteredError: null,
    productUpdateSuccess: null,
    productUpdatError: null,
    productDeleteSuccess: null,
    productDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_START:
      return productsFetchStart(state);
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return productsFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCTS_COUNT_SUCCESS:
      return productsCountFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCTS_FAIL:
      return productsFetchFail(state, action);

    case actionTypes.FETCH_PRODUCTS_FILTERED_START:
      return productsFilteredFetchStart(state);
    case actionTypes.FETCH_PRODUCTS_FILTERED_SUCCESS:
      return productsFilteredFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCTS_FILTERED_COUNT_SUCCESS:
      return productsFilteredCountFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCTS_FILTERED_FAIL:
      return productsFilteredFetchFail(state, action);

    case actionTypes.FETCH_PRODUCT_START:
      return productFetchStart(state);
    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return productFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_FAIL:
      return productFetchFail(state, action);

    case actionTypes.CREATE_PRODUCT_START:
      return productCreateStart(state);
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return productCreateSuccess(state);
    case actionTypes.CREATE_PRODUCT_FAIL:
      return productCreateFail(state, action);

    case actionTypes.UPDATE_PRODUCT_START:
      return productUpdateStart(state);
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return productUpdateSuccess(state, action);
    case actionTypes.UPDATE_PRODUCT_FAIL:
      return productUpdateFail(state, action);

    case actionTypes.DELETE_PRODUCT_START:
      return productDeleteStart(state);
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return productDeleteSuccess(state);
    case actionTypes.DELETE_PRODUCT_FAIL:
      return productDeleteFail(state, action);

    case actionTypes.CLEAR_PRODUCT_MESSAGE_SUCCESS:
      return productMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
