import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { FaArrowRight, FaArrowLeft, FaTrash, FaPen } from "react-icons/fa";
import * as actions from "../../store/actions/exports";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import ActionModal from "../ActionModal";
import { useTranslation } from "react-i18next";
import { API } from "../../store/config";

const ManageProducts = ({
  productsFilteredFetch,
  productsFilteredCount,
  productsFilteredLoading,
  productDeleteSuccess,
  productDeleteError,
  productDeleteLoading,
  productsFiltered,
  productDelete,
  productMessageClear,
  userLogged,
  language,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [deleteElement, setDeleteElement] = useState();
  const [paginationProps, setPaginationProps] = useState({
    limit: 12,
    skip: 0,
    page: 1,
  });
  const [myFilters] = useState({
    order: "desc",
  });
  const { limit, skip, page } = paginationProps;

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const pageSelectHandler = (pageNumber) => {
    productsFilteredFetch(pageNumber * limit, limit, myFilters);
    setPaginationProps({
      ...paginationProps,
      page: pageNumber + 1,
      skip: pageNumber * limit,
    });
  };

  const onDeleteHandler = () => {
    setOpen(false);
    productDelete(deleteElement, userLogged.user._id, userLogged.token);
  };

  useEffect(() => {
    productMessageClear();
    productsFilteredFetch(skip, limit, myFilters);
  }, [productMessageClear, productsFilteredFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productDeleteSuccess) {
      if (productsFiltered.length - 1 !== 0) {
        productsFilteredFetch(skip, limit, myFilters);
      } else if (productsFiltered.length - 1 === 0) {
        const newPage = page > 1 ? page - 2 : 0;
        productsFilteredFetch(newPage * limit, limit, myFilters);
        setPaginationProps({
          ...paginationProps,
          page: newPage + 1,
          skip: newPage * limit,
        });
      }
    }
  }, [productDeleteSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          {productDeleteSuccess &&
            showAlert(t("delete-product-success-message"), "success")}
          {productDeleteError && showAlert(productDeleteError, "danger")}
        </Col>
        <Col xs={12}>
          <div className="section-title">
            <h5>{t("products")}</h5>
          </div>
        </Col>
        {productsFiltered &&
        !productsFilteredLoading &&
        !productDeleteLoading ? (
          productsFilteredCount > 0 ? (
            productsFiltered.map((product, i) => (
              <Col xs={12} sm={6} md={4} className="products">
                <Card className="products-card">
                  <CardBody>
                    <img
                      src={`${API}/uploads/productCategories/${product.category}/${product._id}.jpeg`}
                      alt={`product`}
                    />
                  </CardBody>
                  <CardFooter className="products-card-controls row">
                    <Col xs={12} xl={6} className="pb-3 pr-xl-2 pb-xl-0">
                      <Link to={`/dashboard/update-product/${product._id}`}>
                        <Button className="btn-custom primary" block>
                          <FaPen size={20} />
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={12} xl={6} className="pl-xl-2 pb-xl-0">
                      <Button
                        onClick={() => {
                          setDeleteElement(product._id);
                          onOpenModal();
                        }}
                        className="btn-custom danger"
                      >
                        <FaTrash size={20} />
                      </Button>
                    </Col>
                  </CardFooter>
                </Card>

                {/* <table className="responsive-table">
                <thead>
                  <tr>
                    <th>{t("image")}</th>
                    <th className="actions">{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {productsFiltered.map((product, i) => (
                    <tr key={i}>
                      <td data-title="Image">
                        <img
                          src={`${API}/uploads/productCategories/${product.category}/${product._id}.jpeg`}
                          alt={`product`}
                        />
                      </td>
                      <td data-title="Actions">
                        <Row>
                          <Col xs={6}>
                            <Link
                              to={`/dashboard/update-product/${product._id}`}
                              className="btn-custom primary"
                            >
                              <FaPen size={20} />
                            </Link>
                          </Col>
                          <Col xs={6}>
                            <Button
                              onClick={() => {
                                setDeleteElement(product._id);
                                onOpenModal();
                              }}
                              className="btn-custom danger"
                            >
                              <FaTrash size={20} />
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
              </Col>
            ))
          ) : (
            <Col className="not-found text-center">
              <div>{t("no-results")}</div>
            </Col>
          )
        ) : (
          <Col className="text-center">
            <Loader />
          </Col>
        )}
        {Math.ceil(+productsFilteredCount / limit) > 1 && (
          <Col xs={12} className="mx-auto">
            <ReactPaginate
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(+productsFilteredCount / limit)}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              containerClassName={
                "pagination icon-pagination pagination-center"
              }
              forcePage={page - 1}
              onPageChange={(selectedIndex) => {
                pageSelectHandler(selectedIndex.selected);
              }}
              previousLabel={<FaArrowLeft size={15} />}
              nextLabel={<FaArrowRight size={15} />}
              activeClassName={"active"}
            />
          </Col>
        )}
      </Row>
      <ActionModal
        open={open}
        onCloseModal={onCloseModal}
        onDeleteHandler={onDeleteHandler}
        title="delete-alert-title"
        description="delete-alert-desc"
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    productsFiltered: state.product.productsFiltered,
    productsFilteredSuccess: state.product.productsFilteredSuccess,
    productsFilteredCount: state.product.productsFilteredCount,
    productsFilteredError: state.product.productsFilteredError,
    productsFilteredLoading: state.product.productsFilteredLoading,

    productDeleteSuccess: state.product.productDeleteSuccess,
    productDeleteError: state.product.productDeleteError,
    productDeleteLoading: state.product.productDeleteLoading,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productsFilteredFetch: (skip, limit, myFilters) =>
      dispatch(actions.productsFilteredFetch(skip, limit, myFilters)),
    productDelete: (categoryId, userId, token) =>
      dispatch(actions.productDelete(categoryId, userId, token)),
    productMessageClear: () => dispatch(actions.productMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProducts);
