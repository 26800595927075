import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import routes from "./routes";

const ProtectedRoute = (props) => {
  const {
    isAuth,
    isAdmin,
    isAdminRoute,
    isProtected,
    language,
    component: Component,
    ...rest
  } = props;
  let notAuthorized = isAdminRoute && !isAdmin;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          (notAuthorized && <Home {...props} language={language} />) || (
            <Component {...props} language={language} />
          )
        ) : isProtected ? (
          <Home {...props} language={language} />
        ) : (
          <Component {...props} language={language} />
        )
      }
    />
  );
};

const RoutesSwitch = ({ isAuth, isAdmin, language }) => {
  return (
    <Switch>
      {routes.map((route, id) => (
        <ProtectedRoute
          path={route.path}
          isAuth={isAuth}
          isAdmin={isAdmin}
          component={route.component}
          isAdminRoute={route.isAdminRoute}
          isProtected={route.isProtected}
          key={id}
          language={language}
          exact
        />
      ))}
      <Home language={language} />
    </Switch>
  );
};

export default RoutesSwitch;
