import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import SliderHome from "../components/Sliderhome";
import Abouthome from "../components/Abouthome";
import SliderCategories from "../components/SliderCategories";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";
import { useHistory } from "react-router-dom";
import Contact from "../components/Contact";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
const Home = ({ language }) => {
  const { t } = useTranslation();
  let history = useHistory();
  let homeProducts = t("home-products");
  let splittedHomeProducts = homeProducts.split(" ");
  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />
      <Reveal effect="fadeIn">
        <Element className="jumbotron jumbomain" id={t("link-home")}>
          <SliderHome />
        </Element>
        <div
          className="chevron-container"
          onClick={() => history.push(`/#${t("link-about-us")}`)}
        >
          <div className="chevron"></div>
          <div className="chevron"></div>
          <div className="chevron"></div>
        </div>
      </Reveal>
      <Reveal effect="fadeInUp">
        <Element id={t("link-about-us")}>
          <Abouthome />
        </Element>
      </Reveal>
      <Reveal effect="fadeInUp">
        <Element className="container-fluid pb-0" id={t("link-products")}>
          <div className="row m-2-hor">
            <div className="row">
              <div className="col-12">
                <h1 className="header-categories">
                  {splittedHomeProducts[0]}{" "}
                  <span className="color">{`${splittedHomeProducts[1]} ${splittedHomeProducts[2]}`}</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <SliderCategories language={language} />
            </div>
          </div>
        </Element>
      </Reveal>
      <Reveal effect="fadeInUp">
        <Element id={t("link-contact")}>
          <Contact />
        </Element>
      </Reveal>
    </div>
  );
};
export default withRouter(Home);
