import React, { useState, useCallback, useEffect, useRef } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as actions from "../store/actions/exports";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "./Loader";
import { API } from "../store/config";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

let settings = {
  accessibility: true,
  infinite: true,
  autoplay: true,
  arrows: true,
  draggable: false,
  swipe: false,
  speed: 800,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 5000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const SliderCategories = ({
  productCategoriesFetch,
  productCategories,
  productCategoriesLoading,
  language,
}) => {
  let sliderRef = useRef();
  let history = useHistory();
  const { t } = useTranslation();
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e, link) => {
      if (dragging) {
        e.stopPropagation();
      } else {
        history.push(link);
      }
    },
    [dragging] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    productCategoriesFetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="slick slickproject">
      {language && !productCategoriesLoading && productCategories ? (
        <React.Fragment>
          <Slider
            ref={sliderRef}
            {...settings}
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
          >
            {productCategories.map((category) => (
              <div
                className="itm"
                key={category.id}
                index={category.id}
                onClickCapture={(e) =>
                  handleOnItemClick(
                    e,
                    `${t("category")}/${category.name[language]
                      .toLowerCase()
                      .replace(/ /g, "-")}`
                  )
                }
              >
                <div>
                  <img
                    src={`${API}/uploads/productCategories/${category._id}/image.jpeg`}
                    className="img-fluid"
                    alt="livingroom"
                  />
                </div>
                <div className="desc">
                  <div className="name">{category.name[language]}</div>
                </div>
              </div>
            ))}
          </Slider>
          <FaAngleLeft
            size={30}
            className="left"
            onClick={() => sliderRef.current.slickPrev()}
          />

          <FaAngleRight
            size={30}
            className="right"
            onClick={() => sliderRef.current.slickNext()}
          />
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategories: state.productCategory.productCategories,
    productCategoriesSuccess: state.productCategory.productCategoriesSuccess,
    productCategoriesCount: state.productCategory.productCategoriesCount,
    productCategoriesError: state.productCategory.productCategoriesError,
    productCategoriesLoading: state.productCategory.productCategoriesLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCategoriesFetch: (skip, limit, myFilters) =>
      dispatch(actions.productCategoriesFetch(skip, limit, myFilters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderCategories);
