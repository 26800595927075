import Home from "./pages/Home";
import Categories from "./pages/categories";
import Dashboard from "./pages/Dashboard";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/dashboard/:section",
    component: Dashboard,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/dashboard/:section/:sectionId",
    component: Dashboard,
    isAdminRoute: true,
    isProtected: true,
  },
  {
    path: "/prijava",
    component: Home,
  },
  {
    path: "/login",
    component: Home,
  },
  {
    path: "/kategorijo/:name",
    component: Categories,
  },
  {
    path: "/category/:name",
    component: Categories,
  },
  {
    path: "/authentication/activate/:token",
    component: Home,
  },
  {
    path: "/authentication/reset/:token",
    component: Home,
  },
];

export default routes;
