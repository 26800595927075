import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const DropzoneBasic = ({
  handleFileInput,
  name,
  resetDropzoneState,
  mainImage,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      handleFileInput(acceptedFiles[0], name);
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });
  const thumbs = files.map((file) => (
    <div className="dz-thumb" key={file.name}>
      <div className="dz-thumb-inner">
        <img src={file.preview} className="dz-img" alt={file.name} />
      </div>
    </div>
  ));
  useEffect(() => {
    if (resetDropzoneState) {
      setFiles([]);
    }
  }, [resetDropzoneState]);
  return (
    <section className="drop">
      <div
        {...getRootProps({
          className: `d-flex dropzone ${thumbs.length > 0 ? "short" : null}`,
        })}
      >
        <input {...getInputProps()} />
        <p className="mx-auto my-auto">{`${t("drag-and-drop-file")} ${
          mainImage ? `(.JPEG - 700 x 1000px)` : `(.JPEG)`
        }`}</p>
      </div>
      {thumbs.length > 0 && <div className="thumb-container">{thumbs}</div>}
    </section>
  );
};
export default DropzoneBasic;
