import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  productCategory: null,
  productCategorySuccess: null,
  productCategoryError: null,
  productCategoryLoading: false,

  productCategories: null,
  productCategoriesCount: null,
  productCategoriesSuccess: null,
  productCategoriesError: null,
  productCategoriesLoading: false,

  productCategoryUpdateSuccess: null,
  productCategoryUpdatError: null,
  productCategoryUpdateLoading: false,

  productCategoryDeleteSuccess: null,
  productCategoryDeleteError: null,
  productCategoryDeleteLoading: false,
};

//get all categories
const productCategoriesFetchStart = (state) => {
  return updateObject(state, {
    productCategoriesSuccess: null,
    productCategoriesError: null,
    productCategoriesLoading: true,
  });
};
const productCategoriesFetchSuccess = (state, action) => {
  return updateObject(state, {
    productCategories: action.categories,
    productCategoriesSuccess: true,
    productCategoriesError: null,
    productCategoriesLoading: false,
  });
};
const productCategoriesCountFetchSuccess = (state, action) => {
  return updateObject(state, {
    productCategoriesCount: action.count,
  });
};
const productCategoriesFetchFail = (state, action) => {
  return updateObject(state, {
    productCategoriesError: action.error,
    productCategoriesSuccess: false,
    productCategoriesLoading: false,
  });
};

//get category by Name
const productCategoryFetchStart = (state) => {
  return updateObject(state, {
    productCategorySuccess: null,
    productCategoryError: null,
    productCategoryLoading: true,
  });
};
const productCategoryFetchSuccess = (state, action) => {
  return updateObject(state, {
    productCategory: action.category,
    productCategorySuccess: true,
    productCategoryError: null,
    productCategoryLoading: false,
  });
};
const productCategoryFetchFail = (state, action) => {
  return updateObject(state, {
    productCategoryError: action.error,
    productCategorySuccess: false,
    productCategoryLoading: false,
  });
};

//create category
const productCategoryCreateStart = (state) => {
  return updateObject(state, {
    productCategorySuccess: null,
    productCategoryError: null,
    productCategoryLoading: true,
  });
};
const productCategoryCreateSuccess = (state) => {
  return updateObject(state, {
    productCategorySuccess: true,
    productCategoryError: null,
    productCategoryLoading: false,
  });
};
const productCategoryCreateFail = (state, action) => {
  return updateObject(state, {
    productCategoryError: action.error,
    productCategorySuccess: false,
    productCategoryLoading: false,
  });
};

//update category
const productCategoryUpdateStart = (state) => {
  return updateObject(state, {
    productCategoryUpdateSuccess: null,
    productCategoryUpdateError: null,
    productCategoryUpdateLoading: true,
  });
};
const productCategoryUpdateSuccess = (state, action) => {
  return updateObject(state, {
    productCategory: action.category,
    productCategoryUpdateSuccess: true,
    productCategoryUpdateError: null,
    productCategoryUpdateLoading: false,
  });
};
const productCategoryUpdateFail = (state, action) => {
  return updateObject(state, {
    productCategoryUpdateError: action.error,
    productCategoryUpdateSuccess: false,
    productCategoryUpdateLoading: false,
  });
};

//delete category
const productCategoryDeleteStart = (state) => {
  return updateObject(state, {
    productCategoryDeleteSuccess: null,
    productCategoryDeleteError: null,
    productCategoryDeleteLoading: true,
  });
};
const productCategoryDeleteSuccess = (state) => {
  return updateObject(state, {
    productCategoryDeleteSuccess: true,
    productCategoryDeleteError: null,
    productCategoryDeleteLoading: false,
  });
};
const productCategoryDeleteFail = (state, action) => {
  return updateObject(state, {
    productCategoryDeleteError: action.error,
    productCategoryDeleteSuccess: false,
    productCategoryDeleteLoading: false,
  });
};

//clear category message
const productCategoryMessageClearSuccess = (state) => {
  return updateObject(state, {
    productCategorySuccess: null,
    productCategoryError: null,
    productCategoriesSuccess: null,
    productCategoriesError: null,
    productCategoryUpdateSuccess: null,
    productCategoryUpdatError: null,
    productCategoryDeleteSuccess: null,
    productCategoryDeleteError: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_CATEGORIES_START:
      return productCategoriesFetchStart(state);
    case actionTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return productCategoriesFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_CATEGORIES_COUNT_SUCCESS:
      return productCategoriesCountFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_CATEGORIES_FAIL:
      return productCategoriesFetchFail(state, action);

    case actionTypes.FETCH_PRODUCT_CATEGORY_START:
      return productCategoryFetchStart(state);
    case actionTypes.FETCH_PRODUCT_CATEGORY_SUCCESS:
      return productCategoryFetchSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_CATEGORY_FAIL:
      return productCategoryFetchFail(state, action);

    case actionTypes.CREATE_PRODUCT_CATEGORY_START:
      return productCategoryCreateStart(state);
    case actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS:
      return productCategoryCreateSuccess(state);
    case actionTypes.CREATE_PRODUCT_CATEGORY_FAIL:
      return productCategoryCreateFail(state, action);

    case actionTypes.UPDATE_PRODUCT_CATEGORY_START:
      return productCategoryUpdateStart(state);
    case actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return productCategoryUpdateSuccess(state, action);
    case actionTypes.UPDATE_PRODUCT_CATEGORY_FAIL:
      return productCategoryUpdateFail(state, action);

    case actionTypes.DELETE_PRODUCT_CATEGORY_START:
      return productCategoryDeleteStart(state);
    case actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS:
      return productCategoryDeleteSuccess(state);
    case actionTypes.DELETE_PRODUCT_CATEGORY_FAIL:
      return productCategoryDeleteFail(state, action);

    case actionTypes.CLEAR_PRODUCT_CATEGORY_MESSAGE_SUCCESS:
      return productCategoryMessageClearSuccess(state);

    default:
      return state;
  }
};
export default reducer;
