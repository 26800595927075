import React, { useState, useEffect, useRef } from "react";
import { Row, Col, FormGroup, Alert, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DropzoneBasic from "../DropzoneBasic";
import { connect } from "react-redux";
import * as actions from "../../store/actions/exports";
import { useTranslation } from "react-i18next";

const initialState = {
  name: {
    en: "",
    si: "",
  },
  slug: {
    en: "",
    si: "",
  },
  icon: "",
  formData: new FormData(),
};

const CreateCategory = ({
  productCategorySuccess,
  productCategoryError,
  productCategoryLoading,
  productCategoryCreate,
  productCategoryMessageClear,
  userLogged,
}) => {
  const { t } = useTranslation();
  let form = useRef();
  const [resetDropzoneState, setResetDropzoneState] = useState(false);
  const [values, setValues] = useState({
    name: {
      en: "",
      si: "",
    },
    slug: {
      en: "",
      si: "",
    },
    icon: "",
    formData: new FormData(),
  });
  const { name, slug, formData } = values;

  const handleChange = (name) => (event) => {
    let value = event.target.value;
    let lang = event.target.lang;
    setValues({
      ...values,
      [name]: { ...values[name], [lang]: value },
    });
    formData.set(name, value);
  };

  const handleFileInput = (file, name) => {
    setValues({ ...values, [name]: file });
    formData.set(name, file);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    productCategoryCreate(userLogged.user._id, userLogged.token, formData);
  };

  const clearState = () => {
    form.reset();
    setValues(initialState);
    setResetDropzoneState(!resetDropzoneState);
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    productCategoryMessageClear();
  }, [productCategoryMessageClear]);

  useEffect(() => {
    if (productCategorySuccess) {
      clearState();
    }
  }, [productCategorySuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.name && values.slug) {
      formData.set("name", JSON.stringify(values["name"]));
      formData.set("slug", JSON.stringify(values["slug"]));
    }
  }, [values.name, values.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col xs={12}>
        {productCategoryError && showAlert(productCategoryError, "danger")}
        {productCategorySuccess &&
          showAlert(t("category-create-success-message"), "success")}
      </Col>
      <Col xs={12}>
        <div className="section-title">
          <h5>{t("create-product-category")}</h5>
        </div>
      </Col>
      <Col xs={12}>
        <AvForm onValidSubmit={(e) => submitHandler(e)} ref={(f) => (form = f)}>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <AvField
                  name="title"
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder={t("enter-name-placeholder-en")}
                  onChange={handleChange("name")}
                  value={name["en"]}
                  lang="en"
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <AvField
                  name="subtitle"
                  id="subtitle"
                  type="text"
                  className="form-control"
                  placeholder={t("enter-slug-placeholder-en")}
                  onChange={handleChange("slug")}
                  value={slug["en"]}
                  lang="en"
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <AvField
                  name="title"
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder={t("enter-name-placeholder-si")}
                  onChange={handleChange("name")}
                  value={name["si"]}
                  lang="si"
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <AvField
                  name="subtitle"
                  id="subtitle"
                  type="text"
                  className="form-control"
                  placeholder={t("enter-slug-placeholder-si")}
                  onChange={handleChange("slug")}
                  value={slug["si"]}
                  lang="si"
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <DropzoneBasic
                  handleFileInput={handleFileInput}
                  name={"icon"}
                  jpeg={true}
                  mainImage={true}
                  resetDropzoneState={resetDropzoneState}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <Button
                type="reset"
                className="btn-custom danger"
                onClick={() => clearState()}
              >
                {t("clear")}
              </Button>
            </Col>
            <Col xs={6}>
              <Button type="submit" className="btn-custom primary">
                {!productCategoryLoading ? (
                  t("create")
                ) : (
                  <Spinner color="white" size="sm" children={false} />
                )}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategory: state.productCategory.productCategory,
    productCategorySuccess: state.productCategory.productCategorySuccess,
    productCategoryError: state.productCategory.productCategoryError,
    productCategoryLoading: state.productCategory.productCategoryLoading,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCategoryCreate: (userId, token, categoryData) =>
      dispatch(actions.productCategoryCreate(userId, token, categoryData)),
    productCategoryMessageClear: () =>
      dispatch(actions.productCategoryMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
