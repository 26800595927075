//contact
export const SEND_MAIL_START = "SEND_MAIL_START";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";

export const SEND_USER_ACTIVATION_MAIL_START =
  "SEND_USER_ACTIVATION_MAIL_START";
export const SEND_USER_ACTIVATION_MAIL_SUCCESS =
  "SEND_USER_ACTIVATION_MAIL_SUCCESS";
export const SEND_USER_ACTIVATION_MAIL_FAIL = "SEND_USER_ACTIVATION_MAIL_FAIL";

export const SEND_FORGOT_PASSWORD_MAIL_START =
  "SEND_FORGOT_PASSWORD_MAIL_START";
export const SEND_FORGOT_PASSWORD_MAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_MAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_MAIL_FAIL = "SEND_FORGOT_PASSWORD_MAIL_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const VALIDATE_RESET_TOKEN_START = "VALIDATE_RESET_TOKEN_START";
export const VALIDATE_RESET_TOKEN_SUCCESS = "VALIDATE_RESET_TOKEN_SUCCESS";
export const VALIDATE_RESET_TOKEN_FAIL = "VALIDATE_RESET_TOKEN_FAIL";

export const CLEAR_CONTACT_MESSAGE_SUCCESS = "CLEAR_CONTACT_MESSAGE_SUCCESS";

//user
export const SIGNIN_USER_START = "SIGNIN_USER_START";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAIL = "SIGNIN_USER_FAIL";

export const SIGNOUT_USER_START = "SIGNOUT_USER_START";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNOUT_USER_FAIL = "SIGNOUT_USER_FAIL";

export const SIGNUP_USER_START = "SIGNUP_USER_START";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "SIGNUP_USER_FAIL";

export const ACTIVATE_USER_START = "ACTIVATE_USER_START";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const UPDATE_USER_PASSWORD_START = "UPDATE_USER_PASSWORD_START";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";

export const CLEAR_USER_MESSAGE_SUCCESS = "CLEAR_USER_MESSAGE_SUCCESS";

export const CLEAR_AUTHENTICATION_MESSAGE_SUCCESS =
  "CLEAR_AUTHENTICATION_MESSAGE_SUCCESS";

//product category
export const FETCH_PRODUCT_CATEGORY_START = "FETCH_PRODUCT_CATEGORY_START";
export const FETCH_PRODUCT_CATEGORY_SUCCESS = "FETCH_PRODUCT_CATEGORY_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_COUNT_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_COUNT_SUCCESS";
export const FETCH_PRODUCT_CATEGORY_FAIL = "FETCH_PRODUCT_CATEGORY_FAIL";

export const FETCH_PRODUCT_CATEGORIES_START = "FETCH_PRODUCT_CATEGORIES_START";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAIL = "FETCH_PRODUCT_CATEGORIES_FAIL";

export const FETCH_PRODUCT_CATEGORIES_FILTERED_START =
  "FETCH_PRODUCT_CATEGORIES_FILTERED_START";
export const FETCH_PRODUCT_CATEGORIES_FILTERED_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_FILTERED_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FILTERED_COUNT_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_FILTERED_COUNT_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FILTERED_FAIL =
  "FETCH_PRODUCT_CATEGORIES_FILTERED_FAIL";

export const CREATE_PRODUCT_CATEGORY_START = "CREATE_PRODUCT_CATEGORY_START";
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  "CREATE_PRODUCT_CATEGORY_SUCCESS";
export const CREATE_PRODUCT_CATEGORY_FAIL = "CREATE_PRODUCT_CATEGORY_FAIL";

export const UPDATE_PRODUCT_CATEGORY_START = "UPDATE_PRODUCT_CATEGORY_START";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
  "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAIL = "UPDATE_PRODUCT_CATEGORY_FAIL";

export const DELETE_PRODUCT_CATEGORY_START = "DELETE_PRODUCT_CATEGORY_START";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAIL = "DELETE_PRODUCT_CATEGORY_FAIL";

export const CLEAR_PRODUCT_CATEGORY_MESSAGE_SUCCESS =
  "CLEAR_PRODUCT_CATEGORY_MESSAGE_SUCCESS";

//product
export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_COUNT_SUCCESS = "FETCH_PRODUCTS_COUNT_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";

export const FETCH_PRODUCTS_FILTERED_START = "FETCH_PRODUCTS_FILTERED_START";
export const FETCH_PRODUCTS_FILTERED_SUCCESS =
  "FETCH_PRODUCTS_FILTERED_SUCCESS";
export const FETCH_PRODUCTS_FILTERED_COUNT_SUCCESS =
  "FETCH_PRODUCTS_FILTERED_COUNT_SUCCESS";
export const FETCH_PRODUCTS_FILTERED_FAIL = "FETCH_PRODUCTS_FILTERED_FAIL";

export const CREATE_PRODUCT_START = "CREATE_PRODUCT_START";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";

export const UPDATE_PRODUCT_START = "UPDATE_PRODUCT_START";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const CLEAR_PRODUCT_MESSAGE_SUCCESS = "CLEAR_PRODUCT_MESSAGE_SUCCESS";
