import React, { useState, useEffect, useRef } from "react";
import { Row, Col, FormGroup, Alert, Button, Spinner } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import DropzoneBasic from "../DropzoneBasic";
import { connect } from "react-redux";
import * as actions from "../../store/actions/exports";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const initialState = {
  name: "",
  slug: "",
  icon: "",
  formData: new FormData(),
};

const CreateProduct = ({
  productSuccess,
  productError,
  productLoading,
  productCreate,
  productMessageClear,
  productCategoriesFetch,
  productCategories,
  productCategoriesLoading,
  userLogged,
  language,
}) => {
  const { t } = useTranslation();
  let form = useRef();
  let categorySelectRef = useRef();
  const [resetDropzoneState, setResetDropzoneState] = useState(false);
  const [values, setValues] = useState({
    category: "",
    formData: new FormData(),
  });
  const { formData } = values;

  const handleChange = (name) => (event) => {
    let value = event.value;
    setValues({ ...values, [name]: value });
    formData.set(name, value);
  };

  const handleFileInput = (file, name) => {
    setValues({ ...values, [name]: file });
    formData.set(name, file);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    productCreate(userLogged.user._id, userLogged.token, formData);
  };

  const clearState = () => {
    form.reset();
    categorySelectRef.setValue("");
    setValues(initialState);
    setResetDropzoneState(!resetDropzoneState);
  };

  const productCategoryDropdown = (productCategories) => {
    let categories = [];
    productCategories.forEach((category) => {
      categories.push({
        value: category._id,
        label: <div>{category.name[language]}</div>,
      });
    });
    return (
      <Select
        ref={(f) => (categorySelectRef = f)}
        className="react-select"
        classNamePrefix="select"
        placeholder={t("select-category")}
        options={categories}
        onChange={handleChange("category")}
        name="category"
      />
    );
  };

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  useEffect(() => {
    productCategoriesFetch();
    productMessageClear();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (productSuccess) {
      clearState();
    }
  }, [productSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col xs={12}>
        {productError && showAlert(productError, "danger")}
        {productSuccess &&
          showAlert(t("product-create-success-message"), "success")}
      </Col>
      <Col xs={12}>
        <div className="section-title">
          <h5>{t("create-product")}</h5>
        </div>
      </Col>
      <Col xs={12}>
        <AvForm onValidSubmit={(e) => submitHandler(e)} ref={(f) => (form = f)}>
          <Row>
            <Col xs={12}>
              <FormGroup>
                {!productCategoriesLoading &&
                  productCategories &&
                  productCategoryDropdown(productCategories)}
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <DropzoneBasic
                  handleFileInput={handleFileInput}
                  name={"image"}
                  jpeg={true}
                  resetDropzoneState={resetDropzoneState}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <Button
                type="reset"
                className="btn-custom danger"
                onClick={() => clearState()}
              >
                {t("clear")}
              </Button>
            </Col>
            <Col xs={6}>
              <Button type="submit" className="btn-custom primary">
                {!productLoading ? (
                  t("create")
                ) : (
                  <Spinner color="white" size="sm" children={false} />
                )}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    product: state.product.product,
    productSuccess: state.product.productSuccess,
    productError: state.product.productError,
    productLoading: state.product.productLoading,

    productCategories: state.productCategory.productCategories,
    productCategoriesSuccess: state.productCategory.productCategoriesSuccess,
    productCategoriesError: state.productCategory.productCategoriesError,
    productCategoriesLoading: state.productCategory.productCategoriesLoading,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCreate: (userId, token, categoryData) =>
      dispatch(actions.productCreate(userId, token, categoryData)),
    productCategoriesFetch: (skip, limit, myFilters) =>
      dispatch(actions.productCategoriesFetch(skip, limit, myFilters)),
    productMessageClear: () => dispatch(actions.productMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
