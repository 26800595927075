import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/vertical.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const SliderHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const content = [
    {
      title: `"Dobrodošli"`,
      button: "Izdelke",
      link: `/#${t("link-products")}`,
      image: `${process.env.PUBLIC_URL}/images/slider-1.jpg`,
    },
    {
      title: "Dobrodošli",
      button: "Izdelke",
      link: `/#${t("link-products")}`,
      image: `${process.env.PUBLIC_URL}/images/slider-2.jpg`,
    },
    {
      title: "Dobrodošli",
      button: "Izdelke",
      link: `/#${t("link-products")}`,
      image: `${process.env.PUBLIC_URL}/images/slider-3.jpg`,
    },
  ];

  return (
    <Slider className="slider-wrapper" autoplay={50000}>
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{ background: `url('${item.image}') no-repeat center center` }}
        >
          <div className="inner">
            <h1>{t("sliderhome-title")}</h1>
            <button onClick={() => history.push(item.link)}>
              <span className="shine"></span>
              <span>{t("sliderhome-button")}</span>
            </button>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default SliderHome;
