import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button, FormGroup, Alert, Spinner } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import DropzoneBasic from "../DropzoneBasic";
import Loader from "../Loader";
import { connect } from "react-redux";
import * as actions from "../../store/actions/exports";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const UpdateProduct = ({
  productCategoriesFetch,
  productCategories,
  productCategoriesLoading,
  product,
  productLoading,
  productUpdateSuccess,
  productUpdateLoading,
  productUpdateError,
  productFetch,
  productUpdate,
  productMessageClear,
  userLogged,
  language,
}) => {
  const { t } = useTranslation();
  const { sectionId } = useParams();
  const [values, setValues] = useState({
    category: "",
    formData: new FormData(),
  });
  const { formData } = values;

  const handleChange = (name) => (event) => {
    let value = event.value;
    setValues({ ...values, [name]: value });
    formData.set(name, value);
  };

  const handleFileInput = (file, name) => {
    setValues({ ...values, [name]: file });
    formData.set(name, file);
  };

  const submitHandler = (e) => {
    e.persist();
    productUpdate(sectionId, userLogged.user._id, userLogged.token, formData);
  };

  const productCategoryDropdown = (productCategories, product) => {
    let categories = [];
    productCategories.forEach((category) => {
      categories.push({
        value: category._id,
        label: <div>{category.name[language]}</div>,
      });
    });
    return (
      <Select
        className="react-select"
        classNamePrefix="select"
        placeholder={t("select-category")}
        options={categories}
        onChange={handleChange("category")}
        defaultValue={{
          value: product.category._id,
          label: product.category.name[language],
        }}
        name="category"
      />
    );
  };

  useEffect(() => {
    productCategoriesFetch();
    productMessageClear();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sectionId) {
      productFetch(sectionId);
    }
  }, [sectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (product) {
      setValues({
        ...values,
        category: product.category,
      });
    }
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  const showAlert = (message, color) => <Alert color={color}>{message}</Alert>;

  return (
    <Row>
      <Col xs={12}>
        {productUpdateError && showAlert(productUpdateError, "danger")}
        {productUpdateSuccess &&
          showAlert(t("update-product-success-message"), "success")}
      </Col>
      <Col xs={12}>
        <div className="section-title">
          <h5>{t("update-product")}</h5>
        </div>
      </Col>
      <Col xs={12}>
        {!productLoading && product ? (
          <AvForm onValidSubmit={(e) => submitHandler(e)}>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  {!productCategoriesLoading &&
                    productCategories &&
                    productCategoryDropdown(productCategories, product)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <DropzoneBasic
                    handleFileInput={handleFileInput}
                    name={"image"}
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <Button type="submit" className="btn-custom primary">
                  {!productUpdateLoading ? (
                    t("update")
                  ) : (
                    <Spinner color="white" size="sm" children={false} />
                  )}
                </Button>
              </Col>
            </Row>
          </AvForm>
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    productCategories: state.productCategory.productCategories,
    productCategoriesSuccess: state.productCategory.productCategoriesSuccess,
    productCategoriesLoading: state.productCategory.productCategoriesLoading,
    productCategoriesError: state.productCategory.blogPostError,

    product: state.product.product,
    productSuccess: state.product.productSuccess,
    productLoading: state.product.productLoading,
    productError: state.product.productError,

    productUpdateSuccess: state.product.productUpdateSuccess,
    productUpdateLoading: state.product.productUpdateLoading,
    productUpdateError: state.product.productUpdateError,

    userLogged: state.user.userLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCategoriesFetch: () => dispatch(actions.productCategoriesFetch()),
    productFetch: (productId) => dispatch(actions.productFetch(productId)),
    productUpdate: (productId, userId, token, productData) =>
      dispatch(actions.productUpdate(productId, userId, token, productData)),
    productMessageClear: () => dispatch(actions.productMessageClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduct);
