import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";
import * as actions from "../store/actions/exports";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../components/Spinner";
import { SRLWrapper } from "simple-react-lightbox";
import "react-image-lightbox/style.css";
import { API } from "../store/config";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
const options = {
  settings: {
    disableWheelControls: true,
  },
  caption: {
    showCaption: false,
  },
};
const Categories = ({
  productCategoryFetch,
  productCategory,
  productCategoryLoading,
  productsFilteredFetch,
  productsFiltered,
  productsFilteredLoading,
  productsFilteredCount,
  language,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  let { name } = useParams();
  const [filters, setFilters] = useState({
    order: "asc",
  });
  const [counter, setCounter] = useState(6);
  const [hasMore, setHasMore] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [completedData, setCompleteData] = useState([]);
  let copyName = name;
  let title = copyName.replace(/-/g, " ");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (name) {
      productCategoryFetch(name);
    }
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (language) {
      setCurrentLanguage(language);
    }
  }, [language]);
  useEffect(() => {
    if (productCategory) {
      setFilters({ ...filters, category: productCategory._id });
      productsFilteredFetch(0, counter, {
        ...filters,
        category: productCategory._id,
      });
    }
  }, [productCategory]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (productsFiltered) {
      setCompleteData(productsFiltered);
    }
  }, [productsFiltered]);
  const fetchMoreData = () => {
    setShowSpinner(true);
    if (counter >= productsFilteredCount) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      productsFilteredFetch(0, counter + 6, filters);
      setCounter(counter + 6);
    }, 500);
  };
  useEffect(() => {
    if (currentLanguage && language !== currentLanguage) {
      history.push(`/${t("category")}/${productCategory.slug[language]}`);
    }
  }, [currentLanguage, language]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />
      <div className="jumbotron head" />
      {!productCategoryLoading && productCategory && (
        <React.Fragment>
          <Reveal effect="fadeInUp">
            <section className="container-fluid pb-0">
              <div className="row m-2-hor">
                <div className="col-md-12">
                  <h1 className="heading text-capitalize mt-5">{title}</h1>
                </div>
              </div>
            </section>
          </Reveal>
          <Reveal effect="fadeInUp">
            <SRLWrapper options={options}>
              {completedData.length > 0 ? (
                <InfiniteScroll
                  dataLength={counter}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={showSpinner && <Spinner />}
                  style={{ overflow: "hidden" }}
                >
                  <section className="container-fluid">
                    <div className="row m-2-hor">
                      {productsFiltered.map((product) => (
                        <div className="col-md-4 slick slickproject p-3">
                          <div className="slick-slide d-block">
                            <div>
                              <div className="itm-gallery">
                                <img
                                  src={`${API}/uploads/productCategories/${productCategory._id}/${product._id}.jpeg`}
                                  className="img-fluid"
                                  alt="zdelka"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </InfiniteScroll>
              ) : (
                <Loader />
              )}
            </SRLWrapper>
          </Reveal>
        </React.Fragment>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    productCategory: state.productCategory.productCategory,
    productCategorySuccess: state.productCategory.productCategorySuccess,
    productCategoryLoading: state.productCategory.productCategoryLoading,
    productCategoryError: state.productCategory.productCategoryError,
    productsFiltered: state.product.productsFiltered,
    productsFilteredSuccess: state.product.productsFilteredSuccess,
    productsFilteredCount: state.product.productsFilteredCount,
    productsFilteredLoading: state.product.productsFilteredLoading,
    productsFilteredError: state.product.productsFilteredError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    productCategoryFetch: (categorySlug) =>
      dispatch(actions.productCategoryFetch(categorySlug)),
    productsFilteredFetch: (skip, limit, myFilters) =>
      dispatch(actions.productsFilteredFetch(skip, limit, myFilters)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
