import React from "react";
import { Button, Row, Col } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ActionModal = ({
  open,
  title,
  description,
  onCloseModal,
  onDeleteHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Modal fullscreen="lg" isOpen={open} centered>
      <ModalBody>
        <Row className="justify-content-center">
          <Col xs={10} className="text-center">
            <h5>{t(title)}</h5>
            <p>{t(description)}</p>
          </Col>
          <Col xs={12} lg={6}>
            <Button
              onClick={onCloseModal}
              type="submit"
              className="btn-custom secondary"
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12} lg={6} className="mt-4 mt-lg-0">
            <Button
              onClick={onDeleteHandler}
              type="submit"
              className="btn-custom danger"
            >
              Delete
            </Button>
          </Col>
        </Row>
        <div
          className="modal-close-button"
          onClick={() => {
            localStorage.removeItem("orderData");
            onCloseModal();
          }}
        >
          <FaTimes size={20} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ActionModal;
