import { combineReducers } from "redux";
import contactReducer from "../reducers/contactReducer";
import userReducer from "../reducers/userReducer";
import productCategoryReducer from "../reducers/productCategoryReducer";
import productReducer from "../reducers/productReducer";

const rootReducer = combineReducers({
  user: userReducer,
  contact: contactReducer,
  productCategory: productCategoryReducer,
  product: productReducer,
});

export default rootReducer;
